
import { Component, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ngbd-confirm-box',
  template: `
    <div class="modal-header">
      <h4 class="modal-title">{{confirmationBoxTitle}}</h4>
      <!--<button type="button" class="close" aria-label="Close" (click)="activeModal.close(false)">
        <span aria-hidden="true">&times;</span>
      </button>-->
      <button type="button" class="btn-close" (click)="activeModal.close(false)" aria-hidden="true"></button>
    </div>
    <div class="modal-body">
      <p>{{confirmationMessage}}</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="activeModal.close(true)">Yes</button>
      <button type="button" class="btn btn-light" (click)="activeModal.close(false)">No</button>
    </div>
  `
})
export class ConfirmModal {
  @Input() confirmationBoxTitle;
  @Input() confirmationMessage;

  constructor(public activeModal: NgbActiveModal) { }
}

@Component({
  selector: 'ngbd-alert-box',
  template: `
  <div class="modal-header">
    <h4 class="modal-title">{{alertTitle}}</h4>
  </div>
  <div class="modal-body">
    <p>{{alertMessage}}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="activeModal.close(true)">{{okButtonText}}</button>
  </div>
`
})
export class AlertModal {
  @Input() alertTitle;
  @Input() alertMessage;
  @Input() okButtonText = 'Ok';

  constructor(public activeModal: NgbActiveModal) { }
}

@Component({
  selector: 'ngbd-confirm-box',
  template: `
    <div class="modal-header">
      <h4 class="modal-title">{{confirmationBoxTitle}}</h4>
      <!--<button type="button" class="close" aria-label="Close" (click)="activeModal.close(false)">
        <span aria-hidden="true">&times;</span>
      </button>-->
      <button type="button" class="btn-close" (click)="activeModal.close(false)" aria-hidden="true"></button>
    </div>
    <div class="modal-body">
      <p>{{confirmationMessage}}</p>
      <div class="form-group" style="margin-bottom: -1rem;">
            <div class="form-check form-check-inline">
              <label class="form-check-label">
                <input type="radio" class="form-check-input" name="optionsRadios5" value="Single" (click)=onRadioSelect($event) checked="">
                <i class="input-frame"></i>
                {{Button1Text}}
              </label>
            </div>
            <div class="form-check form-check-inline">
              <label class="form-check-label">
                <input type="radio" class="form-check-input" name="optionsRadios5" value="Multiple" (click)=onRadioSelect($event)>
                <i class="input-frame"></i>
                {{Button2Text}}
              </label>
            </div>  
          </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="activeModal.close(this.download)" >Ok</button>
    </div>
  `
})
export class ConfirmDynamicModal {
  @Input() confirmationBoxTitle;
  @Input() confirmationMessage;
  @Input() Button1Text = '';
  @Input() Button2Text = '';
  download: any = 'Single';

  constructor(public activeModal: NgbActiveModal) { }
  onRadioSelect(e) {
    this.download = e.target.value;
  }

}