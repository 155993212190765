import { Component, ElementRef, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { CommonComponentsService } from 'src/app/shared/common/components/common-components.service';
import { CommonService } from 'src/app/shared/common/services/common.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';

import { ActivatedRoute, Router } from '@angular/router';
import { ExcelService } from 'src/app/shared/common/services/excel.service';
import { DeviceType } from 'ngx-device-detector';

@Component({
  selector: 'app-dashboard-v2',
  templateUrl: './dashboard-v2.component.html',
  styleUrls: ['./dashboard-v2.component.scss']
})
export class DashboardV2Component implements OnInit {
  DeviceTypeID: any = {};
  deviceTypeData: any = {};
  deviceArrayDataList: any = {};
  offlineMessage: boolean = false;
  ppbValue = 0;
  constructor(
    private modalService: NgbModal,
    private cdr: ChangeDetectorRef,
    public formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private commonComponentsService: CommonComponentsService,
    private router: Router,
    private auth: AuthenticationService,
    private commonService: CommonService,
    private excelService: ExcelService) { }

  currentUserId = Number(this.auth.getUserID());
  customDashboardList: any;
  url = 'CustomDashboard/';
  dropdownList: any = {}
  chartWidgetDropdown = [
    { 'id': '1', 'type': 'Tile View' },
    { 'id': '2', 'type': 'Gauge' },
    { 'id': '3', 'type': 'Bar-Graph Vertical' },
    { 'id': '4', 'type': 'Line Graph' },
    { 'id': '5', 'type': 'Heatmap Widget' },
    { 'id': '6', 'type': 'Radar Chart' },
  ];
  averageOptionDropdown = [
    { 'id': '1', 'type': 'Device' },
    { 'id': '2', 'type': 'Group' }
  ];
  timeRangeDropdown = [
    { 'id': '1', 'type': 'Hourly' },
    { 'id': '2', 'type': 'Daily' },
    { 'id': '3', 'type': 'Weekly' },
    { 'id': '4', 'type': 'Monthly' }
  ];
  flair2Matric: any = ["CO2_ppm", "Humd", "MoldIndex", "O3_ppm", "TVOCs_ppb", "Temp_C", "AQI", "PC0.1", "PC0.3", "PC0.5", "PC10", "PC1.0", "PC2.5", "PC5", "PM0.1_ug/m3", "PM0.3_ug/m3", "PM0.5_ug/m3", "PM10_ug/m3", "PM1.0_ug/m3", "PM2.5_ug/m3", "PM5_ug/m3"];
  dashGrouplist: any = {}
  ppbExists = false;
  ngOnInit(): void {
    this.customDashboardGet();
    this.dropdownToList();
    setTimeout(() => {
      this.processData();
    }, 20000);
  }
  manageDashboard() {
    this.router.navigateByUrl("dashboard-v2/create");
  }

  dropdownToList() {
    this.dropdownList['chartWidgetDropdown'] = [];
    this.chartWidgetDropdown.forEach(element => {
      this.dropdownList['chartWidgetDropdown'][element.id] = element.type;
    });
    this.dropdownList['averageOptionDropdown'] = [];
    this.averageOptionDropdown.forEach(element => {
      this.dropdownList['averageOptionDropdown'][element.id] = element.type;
    });
    this.dropdownList['timeRangeDropdown'] = [];
    this.timeRangeDropdown.forEach(element => {
      this.dropdownList['timeRangeDropdown'][element.id] = element.type;
    });
  }

  customDashboardGet() {

    let params = '?isActive=true&pageSize=500&pageNumber=0&userId=' + this.currentUserId
    return new Promise((resolve, reject) => {
      this.commonService.get(this.url + params).subscribe((response: any) => {
        let data: any = response;
        this.customDashboardList = data.data.customDashboardList
        this.customDashboardList.forEach(element => {
          if (element.isgroup) {
            let groupstemp = element.groupid.split(',')
            groupstemp.forEach(group => {
              this.loadGroupList(Number(group))
            });
          } else {
            let devicestemp = element.deviceid.split(',')
            this.getDeviceData(devicestemp)
          }
        });
      }, error => {
        reject(error);
        this.commonComponentsService.openAlert("Error!", 'Error in Updating Custom Dashboard');
      }
      );
    });
  }
  getCSS() {
    return 'bottom-border-green-100::before top-border-green-100:after right-border-green-100::before left-border-green-100::before'
  }
  // =============================Process Data
  processData() {
    this.customDashboardList.forEach(element => {
      if (element.isgroup) {
        let groupstemp = element.groupid
        element['value'] = element.groupid
        element['metricName'] = element.groupid
        element['baseline'] = element.groupid
      } else {
        let devicestemp = element.deviceid
        element['value'] = element.groupid
        element['metricName'] = element.groupid
        element['baseline'] = element.groupid
      }
    });
  }

  // =============================Process Device 
  getDeviceData(deviceIds) {
    return new Promise((resolve, reject) => {
      deviceIds.forEach(deviceId => {
        this.getDeviceTypeID(deviceId);
        let date = new Date();
        date.setMinutes(date.getMinutes() - 5);
        let startDate = date.toISOString();
        let endDate = new Date().toISOString();
        //console.log(this.deviceTypeData[deviceId]);
        //Added condition for FLair2.0 Outdoor Device(deviceId==4) check, under Task:7236, added by Amit Singh.
        let isFlair1 = (this.deviceTypeData[deviceId] == 2 || this.deviceTypeData[deviceId] == 4) ? "&isFlair1=false" : "&isFlair1=true";
        let params = `startDate=${startDate}&endDate=${endDate}&isv2=false&groupby=1d&timezone=America/New_York&deviceId=${deviceId}${isFlair1}`;
        this.commonService.getWhioutLoader('Device/GetDeviceTelemetry_PublicAPI?' + params).subscribe((response: any) => {
          let data: any = JSON.parse(response.data);
          ///console.log('data')
          ///console.log(data)
          if (this.deviceTypeData[deviceId] == 1) {
            data.forEach(element => {
              this.deviceArrayDataList[element.Item1] = {}
              let data2 = JSON.parse(element.Item2);
              data2.forEach(data3 => {
                if (data3['param'] !== undefined) {
                  this.deviceArrayDataList[element.Item1][data3['param']] = data3;
                  if (data3['param'] == 'tvoc-sgp') {
                    let objParam = { param: 'aqi', points: data3.points, source: data3.source, span: data3.span, units: data3.units };
                    this.deviceArrayDataList[element.Item1][objParam['param']] = objParam;
                  }
                }
              });
            });
          }
          if (data.length == 0) {
            this.offlineMessage = true;
          }
          if (this.deviceTypeData[deviceId] == 2 && data.length != 0) {
            this.deviceArrayDataList[data[0].DeviceId] = {}
            let data2 = [];
            if (data[0].DeviceTelemetryJson != undefined) {
              data2 = JSON.parse(data[0].DeviceTelemetryJson);
            }
            //console.log(data2);
            Object.entries(data2).forEach(entry => {
              const [key, value] = entry;
              ///console.log('++ '+key, value);
              if (key == 'O3_ppb') {
                this.ppbExists = true;
                //     data2[key] = 99;
                this.ppbValue = data2[key];
              }
            });

            ///console.log(data2);
            ///console.log('-----------')
            // console.log(this.deviceArrayDataList);
            this.flair2Matric.forEach(element => {
              ///console.log(this.ppbExists +' -- '+element)
              if (this.ppbExists == true && element == 'O3_ppm')
                element = 'O3_ppb';
              this.deviceArrayDataList[data[0].DeviceId][element] = data2[element];
            });
            // console.log(this.deviceArrayDataList);
            // console.log('-----------')

          }
          // 15-Mar-2023
        }, error => {
          reject(error);
          this.commonComponentsService.openAlert("Error!", 'Error in getting Project');
          return {};
        });
      });
    });
  }
  getDeviceTypeID(deviceId) {
    this.commonService.getWhioutLoader('Device/GetDevice_PublicAPI/' + deviceId).subscribe((response: any) => {
      let data: any = response;
      this.DeviceTypeID = data.data.deviceTypeId;
      this.deviceTypeData[deviceId] = data.data.deviceTypeId
      // console.log(this.deviceTypeData);
    });
  }

  // =============================Process Group
  loadGroupList(Groupid) {
    let params = 'isActive=true&pageSize=' + 500 + '&pageNumber=' + 0 + '&groupId=' + Groupid;
    return new Promise((resolve, reject) => {
      this.commonService.get('Group/GetGroupList_PublicAPI?' + params).subscribe((response: any) => {
        // this.commonService.get('Group/GetGroupDropdown?').subscribe((response: any) => {
        let data: any = response;

        this.dashGrouplist[Groupid] = data;
        this.loadGroupListWithTelemetry(Groupid, data.data.groupList);
      }, error => {
        reject(error);
        this.commonComponentsService.openAlert("Error!", 'Error in getting Group');
      });
    });
  }

  getTelemetryAPILink(Groupid, deviceArray) {
    let link = 'Device/GetAllDevicesTelemetrySummary';
    deviceArray.forEach((device, index) => {
      if (index == 0) {
        link = link.concat('?deviceId=', device);
      } else {
        link = link.concat('&deviceId=', device);
      }
      // this.dashGrouplist[Groupid][device] = []
    });
    return link;
  }

  loadGroupListWithTelemetry(Groupid, dataArray) {
    let loadTeleData = []
    return new Promise((resolve, reject) => {
      dataArray.forEach(GroupList => {
        let devices = GroupList.devices.split(",");
        let param = this.getTelemetryAPILink(Groupid, devices);
        this.commonService.get(param).subscribe((response: any) => {
          let data: any = response;
          let telemetrydata = this.telementryDataRestructure(Groupid, data.data);
          let loadTeleData1 = {
            'groupId': GroupList.groupid,
            'groupName': GroupList.groupname,
            'deviceCount': GroupList.devicesCount,
            'telemetryData': telemetrydata
          };
          // loadTeleData.push(loadTeleData1[0]);
          this.dashGrouplist[Groupid]['GroupDetail'] = loadTeleData1
        }, error => {
          reject(error);
          this.commonComponentsService.openAlert("Error!", 'Error in getting Group');
        });

      });
      this.dashGrouplist[Groupid]['Telemetry'] = [];
    });
  }
  telementryDataRestructure(Groupid, telementryData) {
    let deviceDataArray = []
    telementryData.forEach((teleData) => {
      if (teleData.status == null || teleData.status == "OFF") {
        let list = [];
        this.flair2Matric.forEach(element => {
          list[element] = 'OFF';
        });
        this.dashGrouplist[Groupid]['Telemetry'].push(list)
      }
      if (teleData.status != null && teleData.status != "OFF") {
        let data1 = JSON.parse(teleData.deviceTelemetryJson);
        let list = [];
        this.flair2Matric.forEach(element => {
          list[element] = 0;
        });
        this.flair2Matric.forEach(element => {
          if (teleData.deviceTypeId == 2 && data1['Temp_C'] != undefined) {
            data1['Temp_F'] = this.getFahrenheit(data1['Temp_C']);
          }
          // list[element] = teleData.deviceTypeId == 1 && teleData.deviceTypeId != 0 ? data1[this.masterMatricForFlair2[element]] != undefined ? data1[this.masterMatricForFlair2[element]] : 'N/A' : data1[this.masterMatricForFlair2[element]];
          if (teleData.deviceTypeId == 1 && teleData.deviceTypeId != 0) {
            if (data1[element] != undefined) {
              list[element] = data1[element]
            } else {
              list[element] = 0
            }
          } else {
            list[element] = data1[element]
          }
        });
        // deviceDataArray.push(list);
        // this.dashGrouplist[Groupid][teleData.deviceId] = (list);
        this.dashGrouplist[Groupid]['Telemetry'].push(list)
      }
    });
    // this.dashGrouplist[Groupid]['Average'] = this.getTeledata(this.dashGrouplist[Groupid]['Telemetry']);
    return this.getTeledata(this.dashGrouplist[Groupid]['Telemetry']);
    // this.processKiosViewGroupData(this.dashGrouplist);
  }
  getTeledata(dataArray) {
    let data = []
    dataArray.forEach(telemetry => {
      this.flair2Matric.forEach(matric => {
        if (!data[matric]) {
          data[matric] = [telemetry[matric]];
        } else {
          data[matric].push(telemetry[matric]);
        }
      });
    });
    return this.getAverage(data);;
  }
  getAverage(telemetryData) {
    let list = [];
    this.flair2Matric.forEach(matric => {

      let sum = 0;
      let length = 0;
      for (var i = 0; i < telemetryData[matric].length; i++) {
        if (telemetryData[matric][i] != "OFF" && telemetryData[matric][i] != 0) {
          sum += parseInt(telemetryData[matric][i], 10);
          length = length + 1;
        }
      }
      let avg = sum / length;
      list[matric] = Number.isNaN(avg) ? 0 : Math.round((avg + Number.EPSILON) * 100) / 100;
      // list[matric + 'class'] = this.matrixClassNo[matric] == undefined ? null : this.getColor(this.matrixClassNo[matric], Math.round((avg + Number.EPSILON) * 100) / 100);
    });
    // console.log(list);
    return list;
  }
  getFahrenheit(CValue) {
    return CValue * (9 / 5) + 32;
  }

}
