import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AlertModal, ConfirmDynamicModal, ConfirmModal } from './confirm.alert.modal';
import Swal from 'sweetalert2';
@Injectable({
  providedIn: 'root'
})
export class CommonComponentsService {

  constructor(private modalService: NgbModal) { }

  openConfirm(title: any = '', message: any = '') {
    let self = this;
    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false
    };
    return new Promise(async function (resolve) {
      const modalRef = self.modalService.open(ConfirmModal, ngbModalOptions);
      modalRef.componentInstance.confirmationBoxTitle = title;
      modalRef.componentInstance.confirmationMessage = message;

      modalRef.result.then((userResponse) => {
        resolve(userResponse);
      });
    })
  }

  openAlert(title: string = '', message: string = '', okButtonText: string = 'Ok', isToast = true) {
    let self = this, icon;
    switch (title) {
      case 'Success!':
      case 'Success':
      case 'success':
        icon = 'success'
        break;
      case 'Error!':
      case 'Failed!':
      case 'error':
        icon = 'error'
        break;
      case 'Warning!':
      case 'Alert!':
      case 'Alert':
      case 'Failed':
      case 'Delete!':
      case 'Deleted':
        icon = 'info'
        break;
      default:
        icon = 'info'
        break;
    }
    if (isToast) {
      Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 5000, title: title, text: message, icon: icon });
    } else {
      return new Promise(async function (resolve) {
        const modalRef = self.modalService.open(AlertModal);
        modalRef.componentInstance.alertTitle = title;
        modalRef.componentInstance.alertMessage = message;
        modalRef.componentInstance.okButtonText = okButtonText;
        modalRef.result.then((userResponse) => {
          resolve(userResponse);
        });
      })
    }

  }
  openConfirmZip(title: any = '', message: any = '',Button1Text:any='',Button2Text:any='') {
    let self = this;
    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false
    };
    return new Promise(async function (resolve) {
      const modalRef = self.modalService.open(ConfirmDynamicModal, ngbModalOptions);
      modalRef.componentInstance.confirmationBoxTitle = title;
      modalRef.componentInstance.confirmationMessage = message;
      modalRef.componentInstance.Button1Text = Button1Text;
      modalRef.componentInstance.Button2Text = Button2Text;

      modalRef.result.then((userResponse) => {
        resolve(userResponse);
      });
    })
  }

}
