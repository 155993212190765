import { ElementRef, Injectable, ViewChild } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as _ from 'lodash';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, finalize, map } from 'rxjs/operators';
import { jsPDF } from 'jspdf';
//import * as jsPDF from 'jspdf';
//import 'jspdf-autotable';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
// declare let html2canvas: any;
const html2canvas = require('html2canvas');
import * as JSZip from 'jszip';
import * as FileSaverZip from 'file-saver';
import * as moment from 'moment';
import { NgxUiLoaderService } from 'ngx-ui-loader';
@Injectable({
  providedIn: 'root'
})
export class ExcelService {
  @ViewChild('content') content: ElementRef;
  constructor(private http: HttpClient, private ngxLoader: NgxUiLoaderService,) { }
  capturedImage;
  public exportAsExcelFile(json: any[], excelFileName: string): void {

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  public exportAsExcelFileZip(json: any[], dataList: any, fromDate: any, toDate: any, excelFileName: string): void {
    let zip = new JSZip();
    let name = '', self = this;
    let dateStamp = moment(fromDate).format('L') + '-' + moment(toDate).format('L');
    let fileDate = moment(fromDate).format("MMM DD") + '-' + moment(toDate).format("MMM DD");
    dataList.forEach(data => {
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data.schedules);
      const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

      const blob: Blob = new Blob([excelBuffer], {
        type: EXCEL_TYPE
      });
      name = excelFileName + '_' + data.schedulername + '_' + fileDate + '.xls';
      zip.file(name, blob);


    });

    zip.generateAsync({ type: "blob" })
      .then(function (content) {
        // see FileSaver.js
        FileSaverZip(content, excelFileName + '_' + fileDate);
        self.ngxLoader.stop('fileLoader');
      });
  }
  /**
   * @author Jaydatt Mohite
     * @description SavePDF function is used to export report in PDF format using data list
     * @param head get report head
     * @param data get report data list
     * @param headername get report name
     * @param filename get filename
     * @param pagetype get report type (portrait/lanscap)
   */
  public SavePDF(head: any, data: any, headername: any, filename: any, pagetype: any): void {
    let doc = new jsPDF(pagetype);
    doc.setFontSize(14);
    doc.setTextColor('#f0ab64');
    //doc.text(headername, 90, 12);
    let pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
    let pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
    doc.text(headername, pageWidth / 2, 14, { align: 'center' });
    doc.setTextColor('#91c1e6');
    doc.setFontSize(10);
    doc.setTextColor(100);
    doc.setLineWidth(0.5);
    (doc as any).autoTable({
      //header line
      margin: { top: 20 },
      headStyles: {
        lineWidth: {
          top: 0.5,
          bottom: 0.5
        },
      },
      head: head,
      body: data,
      theme: 'plain',
    })

    // below line for Open PDF document in new tab
    // doc.output('dataurlnewwindow')

    // below line for Download PDF document  

    doc.save(`${filename}.pdf`);
  }
  /**
 * @author Jaydatt Mohite
   * @description SavePDF function is used to export report in PDF format using data list
   * @param head get report head
   * @param data get report data list
   * @param headername get report name
   * @param filename get filename
   * @param pagetype get report type (portrait/lanscap)
 */
  public SaveCalendarPDF(head: any, data: any, headername: any, fromDate: any, toDate: any, filename: any, pagetype: any): void {
    let doc = new jsPDF(pagetype);
    let dateStamp = moment(fromDate).format('L') + '-' + moment(toDate).format('L');
    let fileDate = moment(fromDate).format("MMM DD") + '-' + moment(toDate).format("MMM DD");
    doc.setFontSize(14);
    doc.setTextColor('#f0ab64');
    //doc.text(headername, 90, 12);
    let pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
    let pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
    doc.text(headername, pageWidth / 2, 14, { align: 'center' });
    doc.setTextColor('#91c1e6');
    doc.setFontSize(10);
    doc.setTextColor(100);
    doc.setLineWidth(0.5);
    doc.text(dateStamp, pageWidth - 15, 14, { align: 'right' });
    (doc as any).autoTable({
      //header line
      margin: { top: 20 },
      headStyles: {
        lineWidth: {
          top: 0.5,
          bottom: 0.5
        },
      },
      head: head,
      body: data,
      theme: 'plain',
    })

    // below line for Open PDF document in new tab
    // doc.output('dataurlnewwindow')

    // below line for Download PDF document  

    doc.save(`${filename + '_' + fileDate}.pdf`);
  }
  public async SaveCalendarPDFZip(head: any, data: any, dataList, headername: any, fromDate: any, toDate: any, filename: any, pagetype: any): Promise<void> {

    let dateStamp = moment(fromDate).format('L') + '-' + moment(toDate).format('L');
    let fileDate = moment(fromDate).format("MMM DD") + '-' + moment(toDate).format("MMM DD");
    let self = this;
    let count = 0;
    const zip = new JSZip();
    function attacheToZip() {
      let data = dataList[count];
      const doc = new jsPDF(pagetype);
      let file = '';
      headername = data.schedulername + ' Schedule Report';
      doc.setFontSize(14);
      doc.setTextColor('#f0ab64');
      let pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
      let pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
      doc.text(headername, pageWidth / 2, 14, { align: 'center' });
      doc.setTextColor('#91c1e6');
      doc.setFontSize(10);
      doc.setTextColor(100);
      doc.setLineWidth(0.5);
      doc.text(dateStamp, pageWidth - 15, 14, { align: 'right' });
      (doc as any).autoTable({
        margin: { top: 20 },
        headStyles: {
          lineWidth: {
            top: 0.5,
            bottom: 0.5
          },
        },
        head: head,
        body: data.schedules,
        theme: 'plain',
      })
      file = filename + '_' + data.schedulername + '_' + fileDate + '.pdf';
      zip.file(file, doc.output('blob'));
      doc.close()
      count++;
      console.log(count, dataList.length)
      if (count == dataList.length) {
        setTimeout(() => {
          zip.generateAsync({ type: "blob" }).then(async function (content) {
            FileSaverZip(content, filename + '_' + fileDate);
            self.ngxLoader.stop('fileLoader');
          });
        }, 100);
      } else {
        attacheToZip();
      }
    }
    attacheToZip();
  }

  /**
   * @author Jaydatt Mohite
   * @description SaveCalendarPDF function is used to export report in PDF format using canvas image
   * @param element get div id for get a image
   * @param head get report head
   * @param data get report data list
   * @param headername get report name
   * @param filename get filename
   * @param pagetype get report type (portrait/lanscap)
   */
  public SaveCalendarExportPDF(element: any, head: any, data: any, headername: any, filename: any, pagetype: any): void {

    let doc = new jsPDF(pagetype);
    doc.setFontSize(14);
    doc.setTextColor('#f0ab64');
    //doc.text(headername, 90, 12);
    let pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
    let pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
    doc.text(headername, pageWidth / 2, 14, { align: 'center' });
    html2canvas(element.nativeElement).then(canvas => {
      let imgData = canvas.toDataURL('image/png');
      doc.addImage(imgData, 'JPEG', 15, 20, pageWidth - 25, 180);
      return doc
    }).then((doc) => {
      doc.save(filename);
    });


  }

  /**
   * @author Aniket Belhekar
   * @description saveAsExcelFile function is used to export report in excel format using data list
   * @param buffer get report data list
   * @param fileName get filename
   */
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName);

    // FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);//change by Jaydatt
  }
  /**
   * @author Aniket Belhekar
   * @description saveAsExcelFile function is used to export report in excel format using data list
   * @param buffer get report data list
   * @param fileName get filename
   */
  private saveAsExcelFileZip(buffer: any, fileName: string): void {
    var zip = new JSZip();
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    //zip.file(fileName+'.xls', data);
    const name = fileName + '.zip';
    zip.file(name, data);
    zip.generateAsync({ type: "blob" })
      .then(function (content) {
        // see FileSaver.js
        FileSaverZip(content, fileName);
      });

    // FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);//change by Jaydatt
  }


  public filterDataForDownload(input: Array<any>, columnsWithSearch: Array<string>, filter: any) {
    // return input.filter(item => {
    //   for (let i = 0; i < columnsWithSearch.length; i++) {
    //     var colValue = item[columnsWithSearch[i]];
    //     if (!filter || (!!colValue && colValue.toString().toLowerCase().indexOf(filter) !== -1)) {
    //       return true;
    //     }
    //   }
    // });
    if (!input) return null;
    if (!filter) return input;
    filter = filter.toLowerCase();
    return input.filter(function (data) {
      return JSON.stringify(data).toLowerCase().includes(filter);
    });
  }


  SaveProfilePic(apiMethod: string, fileList: FileList) {
    var fileData = new FormData();
    const url = environment.apiUrl + apiMethod;

    if (fileList != null) {
      for (var i = 0; i < fileList.length; i++) {
        fileData.append(fileList[i].name, fileList[i]);
      }
    }

    return this.POSTBLOB(url, fileData);
  }

  POSTBLOB(apiURL, formData) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'multipart/form-data',
      })
    };
    return this.http.post(apiURL, formData).pipe(map((response: Response) => {
      return { Value: response };
    }));
  }

}

