import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, tap } from 'rxjs/operators';
import { ApiResponse } from '../api-response';
import { DeviceDetectorService } from 'ngx-device-detector';
import Swal from 'sweetalert2';
import { formatDate, DOCUMENT } from "@angular/common";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  deviceInfo = null;
  ipAddress: string = "0.0.0.0";
  public spacePresent = false;
  public currentPage = 0;
  public isCustomerDetails = false;

  constructor(
    private http: HttpClient,
    private deviceService: DeviceDetectorService
  ) {}
  private metricKPIDetailsSubject: BehaviorSubject<any | null> = new BehaviorSubject<any | null>(null); 
  private metricKPIDataFetched = false;
  

  downloadFile(url: string): Observable<Blob> {
    return this.http.get(url, { responseType: "blob" });
  }

  post(apiMethod: string, data: any) {
    const url = environment.apiUrl + apiMethod;
    return this.http.post<ApiResponse>(url, JSON.stringify(data)).pipe(
      tap((_) => {}),
      catchError(this.handleError("postMethod", []))
    );
  }

  postWithoutLoader(apiMethod: string, data: any) {
    const url = environment.apiUrl + apiMethod;
    return this.http
      .post<ApiResponse>(url, JSON.stringify(data), {
        headers: { loader: "false" },
      })
      .pipe(
        tap((_) => {}),
        catchError(this.handleError("postMethod", []))
      );
  }

  postWithFormData(apiMethod: string, data: any) {
    const url = environment.apiUrl + apiMethod;
    return this.http
      .post<ApiResponse>(url, data, { headers: { skip: "true" } })
      .pipe(
        tap((_) => {}),
        catchError(this.handleError("postMethod", []))
      );
  }

  postFormData(apiMethod: string, data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/pdf",
      }),
    };
    const url = environment.apiUrl + apiMethod;
    return this.http.post<ApiResponse>(url, data, httpOptions).pipe(
      tap((_) => {}),
      catchError(this.handleError("postMethod", []))
    );
  }

  //common method for put api calling
  put(apiMethod: string, data: any) {
    const url = environment.apiUrl + apiMethod;
    return this.http.put(url, JSON.stringify(data)).pipe(
      tap((_) => {}),
      catchError(this.handleError("putMethod", []))
    );
  }
  putWithFormData(apiMethod: string, data: any) {
    const url = environment.apiUrl + apiMethod;
    return this.http.put(url, data, { headers: { skip: "true" } }).pipe(
      tap((_) => {}),
      catchError(this.handleError("putMethod", []))
    );
  }

  //common method for get api calling
  get(apiMethod: string) {
    const url = environment.apiUrl + apiMethod;
    return this.http.get<ApiResponse>(url).pipe(
      tap((_) => {}),
      catchError(this.handleError("getMethod", []))
    );
  }
  getWithObject(apiMethod: string, data: any) {
    const url = environment.apiUrl + apiMethod;
    return this.http.get(url, data).pipe(
      tap((_) => {}),
      catchError(this.handleError("getMethod", []))
    );
  }
  //common method for get api calling
  getWhioutLoader(apiMethod: string, skiploader = false) {
    const url = environment.apiUrl + apiMethod;
    return this.http
      .get<ApiResponse>(url, { headers: { loader: skiploader.toString() } })
      .pipe(
        tap((_) => {}),
        catchError(this.handleError("getMethod", []))
      );
  }

  //common method for patch api calling for required params only
  patch(apiMethod: string, data: any) {
    const url = environment.apiUrl + apiMethod;
    return this.http.patch(url, JSON.stringify(data)).pipe(
      tap((_) => this.log("putMethod")),
      catchError(this.handleError("putMethod", []))
    );
  }

  //common method for delete api calling
  delete(apiMethod: string) {
    const url = environment.apiUrl + apiMethod;
    return this.http.delete(url).pipe(
      tap((_) => this.log("deleteMethod")),
      catchError(this.handleError("deleteMethod", []))
    );
  }

  getThirdPartyData(apiMethod: string) {
    const headers = new HttpHeaders()
      .set("content-type", "application/json")
      .set(
        "access-control-allow-headers",
        "Origin, X-Requested-With, Content-Type"
      )
      .set("Access-Control-Allow-Origin", "*");
    const url = apiMethod;
    return this.http.get(url, { headers: headers }).pipe(
      tap((_) => {}),
      catchError(this.handleError("getMethod", []))
    );
  }

  //for all the methods common error handler
  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error);
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a HeroService message with the MessageService */
  private log(message: string) {
    console.log(message);
  }

  base64ToBlob(b64Data, fileMimeType, sliceSize = 512) {
    let byteCharacters = atob(b64Data); //data.file there
    let byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);

      let byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: fileMimeType });
  }

  SaveDocument(apiMethod: string, fileList: FileList) {
    const url = environment.apiUrl + apiMethod;
    var fileData = new FormData();
    if (fileList.length > 0) {
      fileData.append("patientDataList", fileList[0]);
    }
    //return this.apiService.POSTBLOB(this.URLSave, fileData);
    return this.http
      .post<ApiResponse>(url, fileList, { headers: { skip: "true" } })
      .pipe(
        tap((_) => {}),
        catchError(this.handleError("postMethod", []))
      );
  }

  /**
   * Epic / Generic function
   */
  epicFunction(): string {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();
    var bDetails = null;
    var device =
      isMobile == true
        ? "Mobile"
        : isTablet == true
        ? "Tablet"
        : isDesktopDevice == true
        ? "Desktop"
        : "Unknown";

    bDetails = {
      Browser:
        this.deviceInfo.browser +
        " Browser Version- " +
        this.deviceInfo.browser_version,
      Device: " DeviceType -" + this.deviceInfo.deviceType,
      OS: this.deviceInfo.os + " - " + this.deviceInfo.os_version,
    };

    localStorage.setItem("UBD", btoa(JSON.stringify(bDetails)));
    return bDetails;
  }

  getIpAddress() {
    this.getVisitorIpAddress().subscribe((res) => {
      this.ipAddress = res["ip"];
      // - Get n Set IP from common service
      localStorage.setItem("UIPADD", btoa(res["ip"].toString()));
    });
    return this.ipAddress;
  }

  /* Get visitor IP Address */
  getVisitorIpAddress() {
    return this.http
      .get("http://api.ipify.org/?format=json")
      .pipe(catchError(this.handleError("GetIPAddressMethod", [])));
  }

  //upload excel file
  uploadFileData(apiMethod: string, fileList: FileList) {
    const url = environment.apiUrl + apiMethod;
    var fileData = new FormData();

    if (fileList.length > 0) {
      fileData.append("patientDataList", fileList[0]);
    }
    //return this.apiService.POSTBLOB(this.URLSave, fileData);
    return this.http
      .post<ApiResponse>(url, fileData, { headers: { skip: "true" } })
      .pipe(
        tap((_) => {}),
        catchError(this.handleError("postMethod", []))
      );
  }

  confirm(confirmationMessage, SuccessfullMessage) {
    Swal.fire({
      title: "Are you sure?",
      text: confirmationMessage,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#34c38f",
      cancelButtonColor: "#f46a6a",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        Swal.fire("Deleted!", SuccessfullMessage, "success");
      }
    });
  }
  successfull(titleMessage) {
    Swal.fire({
      position: "top-end",
      icon: "success",
      title: titleMessage,
      showConfirmButton: false,
      timer: 1500,
    });
  }
  error(titleMessage) {
    Swal.fire({
      position: "top-end",
      icon: "error",
      title: titleMessage,
      showConfirmButton: false,
      timer: 1500,
    });
  }

  onlyAlphabetWithSpaceKey(evt) {
    var keyCode = evt.which ? evt.which : evt.keyCode;
    if (
      (keyCode < 65 || keyCode > 90) &&
      (keyCode < 97 || keyCode > 123) &&
      keyCode != 32
    )
      return false;
    return true;
  }
  onlyAlphabetWithSpaceKeyTrim(evt: any, id: string) {
    var keyCode = evt.which ? evt.which : evt.keyCode;
    if (
      (keyCode < 65 || keyCode > 90) &&
      (keyCode < 97 || keyCode > 123) &&
      keyCode != 32
    )
      return false;
    //    console.log(  (<HTMLInputElement>document.getElementsByName(id)[0]).value +' ' + (<HTMLInputElement>document.getElementsByName(id)[0]).value.length)
    if (
      keyCode == 32 &&
      (<HTMLInputElement>document.getElementsByName(id)[0]).value.length == 1
    )
      (<HTMLInputElement>document.getElementsByName(id)[0]).value = (<
        HTMLInputElement
      >document.getElementsByName(id)[0]).value.trim();

    return true;
  }

  spaceNotAllowed(e) {
    //    console.log('='+e.target.value.trim()+'=')
    if (e.target.value.trim() == "") {
      this.spacePresent = true;
    } else {
      this.spacePresent = false;
    }
  }
  spacePrevent(e: any) {
    if (e.keyCode == 32) e.preventDefault();
  }

  public maskPhone(newVal) {
    if (newVal.length === 0) {
      newVal = "";
    } else if (newVal.length <= 3) {
      newVal = newVal.replace(/^(\d{0,3})/, "($1)");
    } else if (newVal.length <= 6) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, "($1) $2");
    } else if (newVal.length <= 10) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, "($1) $2-$3");
    } else {
      newVal = newVal.substring(0, 10);
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, "($1) $2-$3");
    }
    return newVal;
  }

  public dateAgo(dateFuture: any, dateNow) {
    // dateFuture = new Date(dateFuture)
    // let dateNow: any = new Date()

    let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

    // calculate days
    const days = Math.floor(diffInMilliSeconds / 86400);
    diffInMilliSeconds -= days * 86400;

    // calculate hours
    const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    diffInMilliSeconds -= hours * 3600;

    // calculate minutes
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;

    let difference = "";
    if (days > 0) {
      difference += days === 1 ? `${days} day, ` : `${days} days, `;
    }
    if (hours > 0) {
      difference +=
        hours === 0 || hours === 1 ? `${hours} hour, ` : `${hours} hours, `;
    }

    difference +=
      minutes === 0 || hours === 1
        ? `${minutes} minutes`
        : `${minutes} minutes`;

    return difference + " ago";

    /*    
      if (value) {
        const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
        if (seconds < 29) // less than 30 seconds ago will show as 'Just now'
            return 'Just now';
        const intervals = {
            'year': 31536000,
            'month': 2592000,
            'week': 604800,
            'day': 86400,
            'hour': 3600,
            'minute': 60,
            'second': 1
        };
        let counter;
        for (const i in intervals) {
            counter = Math.floor(seconds / intervals[i]);
            if (counter > 0)
                if (counter === 1) {
                    return counter + ' ' + i + ' ago'; // singular (1 day ago)
                } else {
                    return counter + ' ' + i + 's ago'; // plural (2 days ago)
                }
        }
      }
      return value;
*/

  }

  setMetricsKPI(data: string) {
    console.log(data);
      // Perform company details validation or checks here
      if (data && data !== '') {
          this.metricKPIDetailsSubject.next(data);
          this.metricKPIDataFetched = true;
      } else {
          console.log('Invalid company details.');
      }
  }

  getMetricsKPI(): Observable<string | null> {
    if (!this.metricKPIDataFetched) {
      // Simulate API call to fetch data
      this.getMetricsKPIDetails('common/GetMetricKpi');
    }
    return this.metricKPIDetailsSubject.asObservable();
  }

  getMetricsKPIDetails(apiMethod: string, skiploader = false) {
    const url = environment.apiUrl + apiMethod;
    return this.http.get<ApiResponse>(url, { headers: { loader: skiploader.toString() } }).pipe(
      tap(_ => { }),
      catchError(this.handleError('getMethod', []))
    );
  }
}
