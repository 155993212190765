<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a routerLink="/" class="logo logo-light">
          <span class="logo-sm">
            <img src="assets/images/sai/icon.png" alt="" height="30">
          </span>
          <span class="logo-lg">
            <img src="{{ logo }}" alt="" height="70">
          </span>
        </a>
      </div>
      <button type="button" class="btn btn-sm px-3 font-size-16 header-item" id="vertical-menu-btn"
        (click)="toggleMobileMenu($event)">
        <i class="fa fa-fw fa-bars"></i>
      </button>
    </div>

    <div class="d-flex">

      <div class="dropdown d-inline-block" [autoClose]="true" ngbDropdown>
        <button type="button" class="btn header-item noti-icon" id="page-header-notifications-dropdown"
          ngbDropdownToggle (click)="hideMe()">
          <i class="bx bx-bell bx-tada"></i>
          <span class="badge bg-danger rounded-pill">{{ notificationCount }}</span>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" #notilist
          aria-labelledby="page-header-notifications-dropdown" ngbDropdownMenu>
          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h5 class="m-0">Notifications</h5>
              </div>
              <div class="col-auto">
                <a (click)="goToNotifications()">View All</a>
              </div>
            </div>
          </div>
          <ngx-simplebar style="position: relative; height: 290px;">
            <div *ngIf="(notificationList?.length == 0)" class="text-center">No New Notifications</div>

            <div *ngIf="(notificationList?.length != undefined && notificationList?.length > 0)">
              <div *ngFor="let notification of notificationList; let i = index">
                <div *ngIf="(notification != undefined)">
                  <span class="text-reset notification-item" *ngIf="notification.isDashboardNotificationRead==false">

                    <!-- <div class="media" (click)="updateNotificaion(notification.queueId, $event, i)" *ngIf="(notification.isDashboardNotificationRead == false)"> -->
                    <div class="media">
                      <div class="media-body">
                        <!-- <h6 class="mt-0 mb-1">Notification First Title</h6> -->
                        <i class="fas fa-solid fa-times text-end"
                          (click)="updateNotificaion(notification.queueId, $event, i)" aria-hidden="true"
                          title="Clear Notification"></i>
                        <div class="{{notification.isDashboardNotificationRead ? 'text-muted' : ''}}">
                          <p class="mb-1 notification" [innerHTML]="notification.notificationMessage">
                            <!-- {{notification.notificationMessage}} -->
                          </p>
                        </div>
                        <span class="tsext-reset"><small><i class="mdi mdi-calendar-clock d-none d-xl-inline-block"></i>
                            <i>{{ notification.createdDate }}</i></small></span>
                      </div>
                    </div>

                  </span>
                </div>
              </div>
            </div>

          </ngx-simplebar>
        </div>
      </div>


      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item" ngbDropdownToggle id="page-header-user-dropdown">
          <img class="rounded-circle header-profile-user" src="assets/images/sai/icon.png" alt="Header Avatar">
          <span class="d-none d-xl-inline-block ms-1">{{UserName}}</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <a class="dropdown-item" href="/accountSettings"><i
              class="bx bx-lock-open font-size-16 align-middle me-1"></i> Account Settings </a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="onLogout()"><i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i> Logout </a>
        </div>
      </div>

      <div class="dropdown d-none d-lg-inline-block ms-1">
        <button type="button" class="btn header-item noti-icon" data-toggle="logout" (click)="onLogout()">
          <i class="bx bx-log-in-circle" title="Logout"></i>
        </button>
      </div>
    </div>
  </div>
</header>