export const environment = {
  production: true,
  defaultauth: 'fackbackend',
  firebaseConfig: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: ''
  },
  syncFusion: {
    SECRET_KEY: 'Mgo+DSMBaFt+QHJqVEZrXVNbdV5dVGpAd0N3RGlcdlR1fUUmHVdTRHRcQlpiQX9TdERhUHpaeX0=;Mgo+DSMBPh8sVXJ1S0R+XVFPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9gSXlSfkRjW35ceXFSRmk=;ORg4AjUWIQA/Gnt2VFhiQlBEfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5Ud01iWX1fcn1QTmVV;MjU4NDE2OUAzMjMxMmUzMDJlMzBnOEw1RDZYTlpjVVJ3TWpCOXpJV2U4UXdCdGxCNUs0NlFycW9WZXIvSXZVPQ==;MjU4NDE3MEAzMjMxMmUzMDJlMzBBWDVBQlYzU0s1VUw4TSs4UitTWWIzR0pRMmpMSU5vTGg4T1Rib0ZDQTRVPQ==;NRAiBiAaIQQuGjN/V0d+Xk9FdlRDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS31TcURrWH9ccXZcQGVVWA==;MjU4NDE3MkAzMjMxMmUzMDJlMzBOdEtOQ1VRc1hialFrYmtVNGk2QTArOGhocGF5aFVQb1I2c2UxUlNzNkFBPQ==;MjU4NDE3M0AzMjMxMmUzMDJlMzBKNVdRQXZCUDhUU21TMWl5dXdKUnp2eWcyT2dzdTA1cFBidGtMUG1vcjY4PQ==;Mgo+DSMBMAY9C3t2VFhiQlBEfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5Ud01iWX1fcn1SRWJV;MjU4NDE3NUAzMjMxMmUzMDJlMzBkMnF6ZllTeUJlamVKWjkvWVhycjZRamNNaHZhanMzd2R3bDVNWGlZeUdNPQ==;MjU4NDE3NkAzMjMxMmUzMDJlMzBtdm1SWm1BdmtGbFdmRDZYZUxxc0poOXJ5TENKWGYvb01RU3hvUFh3NnFNPQ==;MjU4NDE3N0AzMjMxMmUzMDJlMzBOdEtOQ1VRc1hialFrYmtVNGk2QTArOGhocGF5aFVQb1I2c2UxUlNzNkFBPQ=='
  },
  tempRandonValueFlag: true,
    activationlink: 'https://thinkliteairportalprod.azurewebsites.net/',  // PROD
  apiUrl: 'https://thinkliteairwebapiprod.azurewebsites.net/api/',//PROD
     kpiConditionValue:  [
    { name: 'Excellent', value: '0' },
    { name: 'Good', value: '51' },
    { name: 'Fair', value: '101' },
    { name: 'Bad', value: '151' },
    { name: 'Hazardous', value: '201' }
  ],
  kpiAQIConditionValues:  [
    { name: 'Excellent', value: '0' },
    { name: 'Good', value: '51' },
    { name: 'Fair', value: '101' },
    { name: 'Bad', value: '151' },
    { name: 'Hazardous', value: '201' }
  ],
  kpiCO2ConditionValues:  [
    { name: 'Excellent', value: '400' },
    { name: 'Good', value: '801' },
    { name: 'Fair', value: '1501' },
    { name: 'Bad', value: '2001' },
    { name: 'Hazardous', value: '2501' }
  ],
  kpiO3ConditionValues:  [
    { name: 'Excellent', value: '0' },
    { name: 'Good', value: '55' },
    { name: 'Fair', value: '71' },
    { name: 'Bad', value: '86' },
    { name: 'Hazardous', value: '106' }
  ],
  kpiTVOCConditionValues:  [
    { name: 'Excellent', value: '0' },
    { name: 'Good', value: '221' },
    { name: 'Fair', value: '661' },
    { name: 'Bad', value: '2001' },
    { name: 'Hazardous', value: '3501' }
  ],
  kpiNO2ConditionValues:  [
    { name: 'Excellent', value: '0' },
    { name: 'Good', value: '54' },
    { name: 'Fair', value: '101' },
    { name: 'Bad', value: '361' },
    { name: 'Hazardous', value: '650' }
  ],
  kpiCOConditionValues:  [
    { name: 'Excellent', value: '0' },
    { name: 'Good', value: '6' },
    { name: 'Fair', value: '10' },
    { name: 'Bad', value: '13' },
    { name: 'Hazardous', value: '16' }
  ],
  kpiPMConditionValues:  [
    { name: 'Excellent', value: '0' },
    { name: 'Good', value: '13' },
    { name: 'Fair', value: '36' },
    { name: 'Bad', value: '56' },
    { name: 'Hazardous', value: '151' }
  ],
  kpiPM10ConditionValues: [
    { name: 'Excellent', value: '0' },
    { name: 'Good', value: '55' },
    { name: 'Fair', value: '155' },
    { name: 'Bad', value: '255' },
    { name: 'Hazardous', value: '355' }
  ],
  
  deviceStatusValues:  [
    { name: '15%', value: '15%' },
    { name: '30%', value: '30%' },
    { name: '45%', value: '45%' },
    { name: '75%', value: '75%' }
  ],
  kpiConditionOperators: [
    { name: 'Above', value: '1' },
    { name: 'At', value: '2' },
    { name: 'Below', value: '4' }
  ]  ,
  deviceStatusDurations: [
    { name: '30 Minutes', value: '30' },
    { name: '1 Hour', value: '60' },
    { name: '1 Day', value: '1440' }
  ]

};
