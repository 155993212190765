import { Component, HostListener, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './core/services/auth.service';
import { AuthfakeauthenticationService } from './core/services/authfake.service';
import { HeroService } from "./hero.service";
import { Title } from '@angular/platform-browser';
import { APP_DETAILS } from 'src/app/constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(private hs: HeroService,
    private authService: AuthenticationService,
    private authFackservice: AuthfakeauthenticationService,
    private titleService: Title) {
    //

  }
  ngOnInit() {
     // Set the HTML title using the constant
     this.titleService.setTitle(APP_DETAILS.title);
    //this.hs.goto('/account/login');
    // document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
  }
  private loggedIn = new BehaviorSubject<boolean>(localStorage.getItem("isLoggedIn") === "true");

  // @HostListener('window:unload', ['$event'])
  // @HostListener('window:load', ['$event'])
  // beforeUnloadHandler(event) {
  //   this.myFunction(event)
  //   event.preventDefault();
  //   return false;
  // }
  // unloadHandler(event) {
  //   this.myFunction(event)
  // }




  myFunction(event) {
    console.log("this event come form: ", event)
    this.logout();
  }


  /**
   * Logout the user
   */
  logout() {
    localStorage.clear();
    delete localStorage.login;
    if (environment.defaultauth === 'firebase') {
      this.authService.logout();
    } else {
      this.authFackservice.logout();
    }
  }

}
