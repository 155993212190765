import { LocationStrategy } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApiResponse } from 'src/app/shared/common/api-response';
import { environment } from 'src/environments/environment';

import { getFirebaseBackend } from '../../authUtils';
import { AccessRights } from '../models/access-rights.model';

import { User } from '../models/auth.models';

@Injectable({ providedIn: 'root' })

export class AuthenticationService {
    isLogin = false;
    roleAs!: string;
    deviceInfo = null;
    headers = null;
    private readonly JWT_TOKEN = 'JWT_TOKEN';
    private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
    jwtHelper = new JwtHelperService();

    user: User;


    constructor(private http: HttpClient,
        private router: Router,
        private locationStrategy: LocationStrategy) {
    }

    /**
     * Returns the current user
     */
    public currentUser(): User {
        return getFirebaseBackend().getAuthenticatedUser();
    }

    /**
     * Performs the auth
     * @param email email of user
     * @param password password of user
     */
    login(email: string, password: string) {
        return getFirebaseBackend().loginUser(email, password).then((response: any) => {
            const user = response;
            return user;
        });
    }

    /**
     * Performs the register
     * @param email email
     * @param password password
     */
    register(email: string, password: string) {
        return getFirebaseBackend().registerUser(email, password).then((response: any) => {
            const user = response;
            return user;
        });
    }

    /**
     * Reset password
     * @param email email
     */
    resetPassword(email: string) {
        return getFirebaseBackend().forgetPassword(email).then((response: any) => {
            const message = response.data;
            return message;
        });
    }

    /**
     * Logout the user
     */
    // logout() {
    //     // logout the user
    //     getFirebaseBackend().logout();
    // }
    /* Logout method */
    logout() {
        localStorage.setItem('signOut', 'true'); //trigger flag
        sessionStorage.removeItem('accessToken'); //Remove token from session
        let params = {
            userLoginInfoId: this.getUserInfoId()
        };
        return this.http.get<string>(`${environment.apiUrl}Auth/Logout?userLoginInfoId=${params.userLoginInfoId}`).pipe(tap((response: string) => {
            this.isLogin = false;
            localStorage.clear();
            this.roleAs = '';
            this.removeTokens();
            return of({ isSuccess: this.isLogin, role: '' });
        }, (error) => {
            localStorage.clear();
        }));
    }

    userlogin(params: {}) {
        this.deviceInfo = localStorage.getItem('UBD') === null ? '' : JSON.parse(atob(localStorage.getItem('UBD')));

        this.headers = {
            LoginSource: this.deviceInfo === null ? '' : this.deviceInfo.Browser,
            IpAddress: localStorage.getItem('UIPADD') === null ? '0.0.0.0' : atob(localStorage.getItem('UIPADD')),
            LoginDevice: this.deviceInfo === null ? '' : this.deviceInfo.Device,
            OsVersion: this.deviceInfo === null ? '' : this.deviceInfo.OS
        };

        return this.http.post<ApiResponse>(`${environment.apiUrl}Auth/Login`, params,)
            // { headers: this.headers })
            .pipe(tap((response: ApiResponse) => {
                // if (response.statusCode == 200) {
                if (response.data.isSuccess == true) {
                    // -Setting local storage for remember me.
                    this.storeTokens(response.data.data);
                    const token = this.getJwtToken();
                    if (token && !this.jwtHelper.isTokenExpired(token)) {
                        //console.log(this.jwtHelper.decodeToken(token));

                        /* Decode the JWT token */
                        const user = this.jwtHelper.decodeToken(token);
                        //console.log(user)
                        this.roleAs = user.role;
                        localStorage.setItem('STATE', 'true');
                        localStorage.setItem('ROLE', user.role);
                        localStorage.setItem('ROLEID', user.UserRoleId);
                        localStorage.setItem('EmailId', user.EmailId);
                        localStorage.setItem('INTRNALUSERID', user.InternalUserId);
                        localStorage.setItem('USERID', user.UserId);
                        localStorage.setItem('PushNotification', user.PushNotification);
                        localStorage.setItem('TimezoneId', user.TimezoneId);
                        localStorage.setItem('CurrentUserDateTime', user.CurrentUserDateTime);

                        // if( user.PushNotification=='True'){
                        //   localStorage.setItem('PushNotification', 'true');
                        // }
                        // else{
                        //   localStorage.setItem('PushNotification', 'false');
                        // }
                        if (user.Profilephotopath != '') {
                            let profilePhotoPath = user.Profilephotopath;
                            localStorage.setItem('USERIMG', profilePhotoPath);
                        } else {
                            if (user.Gender == "Male") {
                                localStorage.setItem('USERIMG', 'assets/images/user/Default-Profile-Pic.png');
                            } else if (user.Gender == "Female") {
                                localStorage.setItem('USERIMG', 'assets/images/user/Default-Profile-Pic.png');
                            } else {
                                localStorage.setItem('USERIMG', 'assets/images/user/Default-Profile-Pic.png');
                            }
                        }
                        localStorage.setItem('USERIMG', 'assets/images/user/Default-Profile-Pic.png');
                        localStorage.setItem('FULLNAME', user.FirstName + ' ' + user.LastName);
                        localStorage.setItem('IsPageRefresh', 'true');
                    }
                    this.isLogin = true;
                } else {
                    this.roleAs = '';
                    this.isLogin = false;
                    localStorage.setItem('STATE', 'false');
                }
                return of({ success: this.isLogin, role: this.roleAs });
            }));
    }


    /* Check is user logged in or not */
    isLoggedIn() {
        const loggedIn = localStorage.getItem('STATE');
        if (loggedIn === 'true') {
            this.isLogin = true;
        } else {
            this.isLogin = false;
        }
        return this.isLogin;
    }

    /* Define a function to handle back button and use anywhere */
    preventBackButton() {
        history.pushState(null, 'null', location.href);
        this.locationStrategy.onPopState(() => {
            history.pushState(null, 'null', location.href);
        })
    }

    /* Get is page refreshed or not */
    getIsPageRefresh() {
        return localStorage.getItem('IsPageRefresh');
    }
    /* Get role */
    getRole() {
        return localStorage.getItem('ROLE');
    }
    getRoleId() {
        return localStorage.getItem('ROLEID');
    }
    getEmailId() {
        return localStorage.getItem('EmailId');
    }

    /* Get user id */
    getUserID() {
        return localStorage.getItem('USERID');
    }
    /* Get user notification status */
    getNotificationStatus() {
        return localStorage.getItem('PushNotification');
    }
    /* Get internal user id */
    getInternalUserID() {
        return localStorage.getItem('INTRNALUSERID');
    }
    /* Get user full name */
    getUserFullName() {
        return localStorage.getItem('FULLNAME');
    }

    /* Get user info id */
    getUserInfoId() {
        return localStorage.getItem('USERLOGININFOID');
    }
    /* Get user image */
    getUserImg() {
        localStorage.setItem('USERIMG', 'assets/images/user/Default-Profile-Pic.png');
        return localStorage.getItem('USERIMG');
    }
     /* Get timezone id */
    getTimeZoneID() {
        return localStorage.getItem('TimezoneId');
      }

       /* Get timezone id */
    getCurrentUserDateTime() {
        return localStorage.getItem('CurrentUserDateTime');
      }

    /* Get refresh token */
    refreshToken() {
        let params = {
            token: this.getJwtToken(),
            refreshToken: this.getRefreshToken()
        };

        return this.http.post<ApiResponse>(`${environment.apiUrl}Auth/Refresh`, params).pipe(tap((response: ApiResponse) => {
            this.removeTokens();
            this.storeTokens(response.data);
        }));
    }

    /* Get JWT token */
    getJwtToken() {
        return localStorage.getItem(this.JWT_TOKEN);
    }

    /* Logout user */
    doLogoutUser() {
        this.removeTokens();
    }

    /* Get refesh token */
    getRefreshToken() {
        return localStorage.getItem(this.REFRESH_TOKEN);
    }

    /* Store JWT token */
    storeJwtToken(jwt: string) {
        localStorage.setItem(this.JWT_TOKEN, jwt);
    }

    /* Store token */
    storeTokens(data: any) {
        localStorage.setItem(this.JWT_TOKEN, data.token);
        localStorage.setItem(this.REFRESH_TOKEN, data.refreshToken);
        localStorage.setItem('USERLOGININFOID', data.userLoginInfoId);
    }

    /* Remove token */
    removeTokens() {
        localStorage.removeItem(this.JWT_TOKEN);
        localStorage.removeItem(this.REFRESH_TOKEN);
    }

    /*****Create by: Suresh Kumar
     Purpose: For getting access permission for user *******/
    getAccessRights(): Observable<AccessRights> {
        let path = this.router.url;
        let accessRights = new AccessRights();
        let menus = [];
        let userid = this.getUserID();
        const url = environment.apiUrl + `Menus/GetUserMenus/${userid}`;
        return this.http.get(url).pipe(map((res: ApiResponse) => {
            if (res.statusCode === 200) {
                menus = res.data;
                if (menus != null && menus != undefined) {
                    menus.forEach(menu => {
                        if (menu.path == path) {
                            return accessRights = {
                                isAdd: menu.isadd,
                                isEdit: menu.isedit,
                                isDelete: menu.isdelete
                            };
                        }
                        menu.subMenuList.forEach(submenu => {
                            if (submenu.path == path)
                                return accessRights = {
                                    isAdd: submenu.isadd,
                                    isEdit: submenu.isedit,
                                    isDelete: submenu.isdelete
                                };
                        });
                    });
                }
            }
            return accessRights;
        }
        ))
    }


    getAccessRightsOld() {
        let path = this.router.url;
        let accessRights = new AccessRights();
        let menus = JSON.parse(localStorage.getItem("menus"));
        if (menus != null && menus != undefined) {
            menus.forEach(menu => {
                if (menu.path == path) {
                    accessRights = {
                        isAdd: menu.isadd,
                        isEdit: menu.isedit,
                        isDelete: menu.isdelete
                    };
                }
                menu.subMenuList.forEach(submenu => {
                    if (submenu.path == path)
                        accessRights = {
                            isAdd: submenu.isadd,
                            isEdit: submenu.isedit,
                            isDelete: submenu.isdelete
                        };
                });
            });
        }
        return accessRights;
    }
}
