<div class="container-fluid">
    <div class="row">
        <div class="col-8">
        </div>
        <div class="col-4">
            <div class="float-end">
                <button type="button" class="btn btn-primary" (click)="manageDashboard()"><i
                        class="fas fa-arrow-right"></i>
                    Manage Dashboard </button>
            </div>
        </div>
    </div>
    <div class=" col-lg-12 col-md-21 col-sm-12 ">
        <div class="row">
            <ng-container *ngFor="let dash of customDashboardList;let i = index">
                <div class="col-lg-4 col-md-4 in-count" style="padding-bottom: 10px;">
                    <div class="small-box {{getCSS()}}">
                        <h5 class="sub-txt">Project Name: --</h5>
                        <h5 class="sub-txt">Group Name: ---</h5>
                        <h5 class="sub-txt">Number of Monitors: 03</h5>
                        <h6 class="small-value small-center">26</h6>

                        <h6 class="small-unit small-center">Matric/Unit</h6>
                        <h6 class="small-baseline small-center">Baseline Value: 22</h6>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>