import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// import { AuthGuard } from './core/guards/auth.guard';
import { LayoutComponent } from './layouts/layout.component';
import { KioskStaticViewComponent } from './kios/kiosk-view/kiosk-static-view/kiosk-static-view.component';
import { Page404Component } from './extrapages/page404/page404.component';
import { AuthGuard } from './shared/security/auth.guard';

const routes: Routes = [
  { path: 'auth', loadChildren: () => import('./account/auth/auth.module').then(m => m.AuthModule) },

  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'notifications',
        loadChildren: () => import('./kios/notifications/notifications.module').then(m => m.NotificationsModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./kios/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'analytics',
        loadChildren: () => import('./kios/analytics/analytics.module').then(m => m.AnalyticsModule)
      },
      
      {
        path: 'heatmap',
        loadChildren: () => import('./kios/heatmap/heatmap.module').then(m => m.HeatmapModule)
      },
      {
        path: 'user',
        loadChildren: () => import('./kios/account-setting/users-settings/user-settings.module').then(m => m.UserSettingsModule)
      },
      {
        path: 'channelPartner',
        loadChildren: () => import('./kios/account-setting/channel-partner/channel-partner.module').then(m => m.ChannelPartnerModule)
      },
      {
        path: 'kpi-template',
        loadChildren: () => import('./kios/kpi/kpi.module').then(m => m.KpiModule)
      },
      {
        path: 'direct',
        loadChildren: () => import('./kios/account-setting/direct-customers/direct-customers.module').then(m => m.DirectCustomerModule)
      },
      {
        path: 'project',
        loadChildren: () => import('./kios/account-setting/projects/projects.module').then(m => m.projectsModule)
      },
      {
        path: 'project-detail-view',
        loadChildren: () => import('./kios/account-setting/project-detail-view/project-detail-view.module').then(m => m.ProjectDetailViewModule)
      },
      {
        path: 'group',
        loadChildren: () => import('./kios/groupView/group-view/group-view.module').then(m => m.GroupViewModule)
      },
      {
        path: 'pages',
        loadChildren: () => import('./extrapages/extrapages.module').then(m => m.ExtrapagesModule)
      },
      {
        path: '',
        loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
      },
      {
        path: 'customers',
        loadChildren: () => import('./kios/account-setting/direct-customers/direct-customers.module').then(m => m.DirectCustomerModule)
      },
      {
        path: 'channel-partner',
        loadChildren: () => import('./kios/account-setting/channel-partner/channel-partner.module').then(m => m.ChannelPartnerModule)
      },
      {
        path: 'menu-mapping',
        loadChildren: () => import('./kios/menu-mapping/menu-mapping.module').then(m => m.MenuMappingModule)
      },
      {
        path: 'menu-mapping-view',
        loadChildren: () => import('./kios/menu-mapping/menu-mapping-view/menu-mapping-view.module').then(m => m.MenuMappingViewModule)
      },
      {
        path: 'user-menu-mapping',
        loadChildren: () => import('./kios/user-menu-mapping/user-menu-mapping/user-menu-mapping.module').then(m => m.UserMenuMappingModule)
      },
      {
        path: 'baseline-list',
        loadChildren: () => import('./kios/baseline/baseline-list/baseline-list.module').then(m => m.BaselineListModule)
      },
      {
        path: 'baseline-addupdate',
        loadChildren: () => import('./kios/baseline/baseline-addupdate/baseline-addupdate.module').then(m => m.BaselineAddupdateModule)
      },
      {
        path: 'add-kpi',
        loadChildren: () => import('./kios/baseline/add-kpi/add-kpi.module').then(m => m.AddKpiModule)
      },
      {
        path: 'groupView',
        loadChildren: () => import('./kios/groupView/group-view/group-view.module').then(m => m.GroupViewModule)
      },
      {
        path: 'groupAddUpdate',
        loadChildren: () => import('./kios/groupView/group-add-update/group-add-update.module').then(m => m.GroupAddUpdateModule)
      },
      {
        path: 'dashboard-v2',
        loadChildren: () => import('./kios/dashboard-v2/dashboard-v2.module').then(m => m.DashboardV2Module)
      },
      {
        path: 'dashboard-v2-create',
        loadChildren: () => import('./kios/dashboard-v2/dashboard-v2-create/dashboard-v2-create.module').then(m => m.DashboardV2CreateModule)
      },


    ]
  },


  { path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
  // tslint:disable-next-line: max-line-length
  // { path: '', component: LayoutComponent, loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule), canActivate: [AuthGuard] },
  // { path: '', component: LayoutComponent, loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule) },
  { path: 'accountSettings', component: LayoutComponent, loadChildren: () => import('./kios/account-setting/account-settings/account-settings.module').then(m => m.AccountSettingsModule) },
  // { path: 'channelPartner', component: LayoutComponent, loadChildren: () => import('./kios/account-setting/channel-partner/channel-partner.module').then(m => m.ChannelPartnerModule) },
  // { path: 'user', component: LayoutComponent, loadChildren: () => import('./kios/account-setting/users-settings/user-settings.module').then(m => m.UserSettingsModule) },
  // { path: 'project', component: LayoutComponent, loadChildren: () => import('./kios/account-setting/projects/projects.module').then(m => m.projectsModule) },
  // { path: 'project-detail-view', component: LayoutComponent, loadChildren: () => import('./kios/account-setting/project-detail-view/project-detail-view.module').then(m => m.ProjectDetailViewModule) },
  // { path: 'group', component: LayoutComponent, loadChildren: () => import('./kios/account-setting/groups/group.module').then(m => m.GroupModule) },
  // { path: 'pages', loadChildren: () => import('./extrapages/extrapages.module').then(m => m.ExtrapagesModule), canActivate: [AuthGuard] },
  // { path: 'pages', loadChildren: () => import('./extrapages/extrapages.module').then(m => m.ExtrapagesModule) },
  // { path: 'direct', component: LayoutComponent, loadChildren: () => import('./kios/account-setting/direct-customers/direct-customers.module').then(m => m.DirectCustomerModule) },
  { path: 'kioskStaticView', component: KioskStaticViewComponent },



  { path: '**', component: Page404Component },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
