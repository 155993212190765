import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpClient
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { ApiResponse } from '../common/api-response';
// import Token from '@angular/compiler/src/ml_parser/lexer';
import { LoaderService } from '../services/loader.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { CommonComponentsService } from '../common/components/common-components.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Injectable()

export class HttpConfigInterceptor implements HttpInterceptor {

  /* declared variables */
  // objToken!: Token[];
  shown = false;
  constructor(public authService: AuthenticationService, private http: HttpClient, private loaderService: LoaderService,
    private ngxLoader: NgxUiLoaderService, private commonComponentsService: CommonComponentsService, private router: Router,
    private modalService: NgbModal) { }

  private onEnd(): void {
    this.hideLoader();
  }

  private showLoader(): void {
    this.ngxLoader.start();
  }

  private hideLoader(): void {
    this.ngxLoader.stop();
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token: string = this.authService.getJwtToken() || '';
    const skipIntercept = request.headers.has('skip');
    const isLoader = request.headers.has('loader');

    /* Add / Set header as per condition */
    if (token) {
      request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
    }
    /* Skip header as per condition */
    if (skipIntercept) {
      request = request.clone({ headers: request.headers.delete('skip') });
      request = request.clone({ headers: request.headers.set('Accept', 'application/json, text/plain, */*') });
    } else {
      if (!request.headers.has('Content-Type')) {
        request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
      }
      request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
    }
    if (!isLoader) {
      request = request.clone({ headers: request.headers.delete('loader') });
      this.showLoader();
    } else {
      request = request.clone({ headers: request.headers.delete('loader') });
    }

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.onEnd();
        }
        return event;
      },
        (err: any) => {
          this.onEnd();
        }),
      catchError((error: HttpErrorResponse) => {
        this.onEnd();
        let data = {};
        if (error.status === 401) {
          this.modalService.dismissAll();
          this.router.navigate(['/auth/login']);
          if (!this.shown) {
            this.shown = true;
            this.commonComponentsService.openAlert('Alert', 'Your session is expired, please login again!', 'Ok', false).then(res => {
              this.router.navigate(['/auth/login']);
            })
          }
        }
        else {
          data = {
            reason: error && error.error && error.error.reason ? error.error.reason : '',
            message: error && error.error && error.message ? error.message : '',
            status: error.status
          };
          return throwError(error);
        }
      }));
  }

  /* handel 401 error */
  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    return this.authService.refreshToken().pipe(
      switchMap((data: ApiResponse) => {
        request = request.clone({ headers: request.headers.delete('Authorization') });
        request = request.clone({
          headers: request.headers.set('Authorization', 'Bearer ' + this.authService.getJwtToken())
        });
        return next.handle(request);
      }));
  }

  /* Add token in request */
  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        'Authorization': `Bearer ${token}`
      }
    });
  }
}