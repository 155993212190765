// Task priorities
export enum DEVICE_TYPE {
   Flair1 = 1,
   Flair2 = 2,
   Flair4 = 4
}

export const PAGINATION = {
    pageSize_25: 25,
    pageSize_100: 100,
    pageSize_500: 500,
    pageSize_1000: 1000,
};

export const APP_DETAILS  = {
    title: "ThinkLite Air",
    logo: "assets/images/sai/thinkliteair.png",
    copyRight: ""
 }

// API endpoints