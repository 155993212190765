import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UIModule } from './ui/ui.module';

import { WidgetModule } from './widget/widget.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TimezonepipePipe } from './common/pipes/timezonepipe.pipe';

@NgModule({
  declarations: [
  
    TimezonepipePipe
  ],
  imports: [
    CommonModule,
    UIModule,
    WidgetModule,
    FormsModule, 
    ReactiveFormsModule, 
    NgbModule
  ],
  exports: [TimezonepipePipe]
})

export class SharedModule { }