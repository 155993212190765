import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { MenuItems } from 'src/app/layouts/sidebar/menu.model';
@Injectable()
export class RightSidebarService {


  private menuList$ = new ReplaySubject<MenuItems[]>();

  constructor() { }

  getMenuList(): Observable<any> {
    if (localStorage.getItem("menus") != null) {
      this.menuList$.next(JSON.parse(localStorage.getItem("menus")));
    }
    return this.menuList$.asObservable();
  }

  setMenuList(menuList: any) {
    this.menuList$.next(menuList);
  }
}
