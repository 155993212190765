import { Component, OnInit } from '@angular/core';
import { Router, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnInit {

  isLoading: boolean;

  constructor(private router: Router, private ngxLoader: NgxUiLoaderService) {

    // Spinner for lazyload modules
    router.events.forEach((event) => {
      if (event instanceof RouteConfigLoadStart) {
        // this.isLoading = true;
        this.ngxLoader.start();
      } else if (event instanceof RouteConfigLoadEnd) {
        // this.isLoading = false;
        this.ngxLoader.stopAll();
      }
    });


  }

  ngOnInit(): void {
  }

}
