import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timezonepipe'
})
export class TimezonepipePipe implements PipeTransform {

  timezone: string;

  transform(value: any): any  {
    
    switch (value) {
      case 'America/Anchorage':      
        this.timezone = 'AKST';
        break;
      case 'America/Argentina/San_Juan':
        this.timezone = 'ART';
        break;
      case 'America/Asuncion':
        this.timezone = 'PRST';
        break;
      case 'America/Bogota':
        this.timezone = 'SAPST';
        break;
      case 'America/Buenos_Aires':
        this.timezone = 'ART';
        break;
      case 'America/Caracas':
        this.timezone = 'VST';
        break;
      case 'America/Cayenne':
        this.timezone = 'SAEST';
        break;
      case 'America/Chicago':
        this.timezone = 'CST';
        break;
      case 'America/Chihuahua':
        this.timezone = 'MSTM';
        break;
      case 'America/Cuiaba':
        this.timezone = 'CBST';
        break;
      case 'America/Denver':
        this.timezone = 'MST';
        break;
      case 'America/Fortaleza':
        this.timezone = 'SAEST';
        break;
      case 'America/Godthab':
        this.timezone = 'GNST';
        break;
      case 'America/Guatemala':
        this.timezone = 'CAST';
        break;
      case 'America/Halifax':
        this.timezone = 'AST';
        break;
      case 'America/Indianapolis':
        this.timezone = 'EST';
        break;
      case 'America/Indiana/Indianapolis':
        this.timezone = 'EST';
        break;
      case 'America/La_Paz':
        this.timezone = 'SAWST';
        break;
      case 'America/Los_Angeles':
        this.timezone = 'PT';
        break;
      case 'America/Mexico_City':
        this.timezone = 'CST';
        break;
      case 'America/Montevideo':
        this.timezone = 'MVST';
        break;
      case 'America/New_York':
        this.timezone = 'EST';
        break;
      case 'America/Phoenix':
        this.timezone = 'MST';
        break;
      case 'America/Regina':
        this.timezone = 'CCST';
        break;
      case 'America/Santa_Isabel':
        this.timezone = 'PST';
        break;
      case 'America/Santiago':
        this.timezone = 'PSAST';
        break;
      case 'America/Sao_Paulo':
        this.timezone = 'ESAST';
        break;
      case 'America/St_Johns':
        this.timezone = 'NST';
        break;
      case 'America/Tijuana':
        this.timezone = 'PST';
        break;
        case 'Pacific/Apia':
        this.timezone = 'SST';
        break;
        case 'Pacific/Auckland':
        this.timezone = 'NZST';
        break;
        case 'Pacific/Fiji':
        this.timezone = 'FJT';
        break;
        case 'Pacific/Guadalcanal':
        this.timezone = 'SBT';
        break;
        case 'Pacific/Guam':
        this.timezone = 'WPST';
        break;
        case 'Pacific/Honolulu':
        this.timezone = 'HAST';
        break;
        case 'Pacific/Port_Moresby':
        this.timezone = 'WPST';
        break;
        case 'Pacific/Tongatapu':
        this.timezone = 'PHOT';
        break;
        case 'Asia/Calcutta':
            this.timezone = 'IST';
            break;
        case 'UTC':
          this.timezone = 'UTC';
          break;
    }

    return this.timezone;
  }

}