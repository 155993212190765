import { Component, OnInit, Output, EventEmitter, Inject, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { AuthenticationService } from '../../core/services/auth.service';
import { AuthfakeauthenticationService } from '../../core/services/authfake.service';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { LanguageService } from '../../core/services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/app/shared/common/services/common.service';
import { Observable, interval} from 'rxjs';
import 'rxjs/add/observable/interval';
import { APP_DETAILS } from 'src/app/constants';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})

/**
 * Topbar component
 */
export class TopbarComponent implements OnInit {

  element;
  cookieValue;
  flagvalue;
  countryName;
  valueset;
  UserName: any;
  UserEmail: any;
  userList: any;
  UserId: string;
  currentuserid: any;
  notificationList = [];
  notificationCount = 0;
  sub: any;
  logo = APP_DETAILS.logo;

  constructor(@Inject(DOCUMENT) private document: any, private router: Router, private authService: AuthenticationService,
    private authFackservice: AuthfakeauthenticationService,
    public languageService: LanguageService,
    public translate: TranslateService,
    public _cookiesService: CookieService,
    private commonService: CommonService) {
  }

  openMobileMenu: boolean;
  dateAgo = this.commonService.dateAgo;

  @ViewChild("notilist") notilist: ElementRef;
  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  ngOnInit() {
    this.openMobileMenu = false;
    this.element = document.documentElement;
    this.UserEmail = localStorage.getItem('EmailId');
    this.UserId = localStorage.getItem('USERID');
    this.currentuserid = this.authService.getUserID();
    this.getUserDetails();
    
    this.getNotificaions();

    var elements = document.getElementsByClassName("dropdown-menu");

    // this.sub = Observable.interval(900000)
    // .subscribe((val) => { 

    //   if ( window.getComputedStyle(elements[0]).display == 'none' )
    //     this.getNotificaions();
      
    //  });


  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  logout() {
    localStorage.clear();
    delete localStorage.login;
    if (environment.defaultauth === 'firebase') {
      this.authService.logout();
    } else {
      this.authFackservice.logout();
    }
    this.router.navigate(['/account/login']);
  }

  onLogout() {
    this.authService.logout().subscribe((res) => {
      if (res == 'Success') {
        this.router.navigate(['/account/login']);
      }
    });
  }

  getUserDetails() {
    this.commonService.get(`Users?userId=${this.UserId}&currentUserId=${this.currentuserid}&pageSize=10&pageNumber=0`).subscribe((response: any) => {
      let data: any = response;
      this.UserName = response.data.userList[0].username;
      //console.log("response.data.userList[0]",response.data.userList[0])
      if(response.data.userList[0].companylogopath!==undefined && response.data.userList[0].companylogopath!='')
        this.logo = response.data.userList[0].companylogopath; 
      else 
        this.logo = APP_DETAILS.logo;
       //console.log("getUserDetails ",this.logo);
    }, error => {
      console.log(error);
      this.logo = APP_DETAILS.logo;
    });
  }

  showMe()
  {
    let showFound = false;
    for ( let k=0; this.notilist.nativeElement.classList.length; k++ )
    {
      if ( this.notilist.nativeElement.classList[k] == 'show' )
      {
        showFound = true;
        break
      }
    }
    if ( showFound == true )
      this.notilist.nativeElement.classList.remove('show');
    else
      this.notilist.nativeElement.classList.add('show');
  }
  hideMe()
  {
    this.notilist.nativeElement.classList.remove('show');
  }
  goToNotifications()
  {
    this.notilist.nativeElement.classList.remove('show');
    this.router.navigate(['/notifications']);
  }
  getNotificaions()
  {
      return new Promise((resolve, reject) => {

      this.commonService.getWhioutLoader('Notification/GetNotifications?isDashboardNotificationRead=' + false, true).subscribe((response: any) => {

        this.notificationList = [];
        let data: any = response;
        this.notificationList = data.data;

        let currentDateTime = new Date(data.currentDateTime + 'Z');

        let notificationArray = [];
        for (let i = 0; i < this.notificationList?.length; i++) {
          this.notificationList[i].createdDate = new Date(this.notificationList[i].createdDate + 'Z');

          this.notificationList[i].createdDate = this.dateAgo(new Date(this.notificationList[i].createdDate), currentDateTime),
            this.notificationList[i].notificationMessage = this.notificationList[i].notificationMessage
        }

        //console.log(this.notificationList)
        //this.notificationCount = this.notificationList.length

              this.notificationCount = 0;
              for (let i = 0; i < this.notificationList?.length; i++ )
              {
                if ( this.notificationList[i]['isDashboardNotificationRead'] == false )
                  this.notificationCount = this.notificationCount + 1;
              }


          }, error => {
            reject(error);
          });
        });
  }

  updateNotificaion(id: number, e: any, i: number)
  {
//    alert ( this.document.getElementsByClassName('noti'+id).remo  )

      let data = [{isDashboardNotificationRead:true}]
//      this.notificationCount = this.notificationList.length;
      if ( this.notificationCount > 0 )
        this.notificationCount--;

      return new Promise((resolve, reject) => {

          this.commonService.post('Notification/UpdateNotification?notificationId=' + id, data).subscribe((response: any) => {
            
//console.log(response)
            if ( response.status == 'Success')
            {
                delete this.notificationList[i];
                this.notificationList[i]['isDashboardNotificationRead'] = true;
//                this.notificationCount = this.notificationCount - 1;
            }

          }, error => {
            reject(error);
          });
        });
  }

  /* Calculate time diference */
  timeDiffCalc(dateFuture, dateNow) {
    let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

    // calculate days
    const days = Math.floor(diffInMilliSeconds / 86400);
    diffInMilliSeconds -= days * 86400;

    // calculate hours
    const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    diffInMilliSeconds -= hours * 3600;

    // calculate minutes
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;

    let difference = '';
    if (days > 0) {
      difference += (days === 1) ? `${days} day, ` : `${days} days, `;
    }
    if (hours > 0) {
      difference += (hours === 0 || hours === 1) ? `${hours} hour, ` : `${hours} hours, `;
    }

    difference += (minutes === 0 || hours === 1) ? `${minutes} minutes` : `${minutes} minutes`;

    return difference + " ago";
  }
}
