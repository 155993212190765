<!-- ========== Left Sidebar Start ========== -->
<ng-template #contentTemplate>
  <!--- Sidemenu -->
  <div class="sidebar-body" id="sidebar-menu" (mouseenter)="operSidebarFolded()" (mouseleave)="closeSidebarFolded()" [perfectScrollbar]>
    <!-- Left Menu Start -->
    <ul class="metismenu list-unstyled" id="sidebar-menu" #sidebarMenu>
      <ng-container *ngFor="let item of menuItems">
        <li class="menu-title" *ngIf="item.isTitle">{{ item.menuname }}</li>
        <li *ngIf="!item.isTitle && !item.isLayout">
          <a class="nav-link" *ngIf="hasItems(item)" href="javascript:void(0);">
              <i class="bx {{item.menuicon}}"></i>
            <span class="link-title"> {{ item.menuname }}</span>
            <i class="fas fa-angle-down" style="margin-left: 10%;"></i>
          </a>

          <a class="nav-link nav-link-ref" [routerLink]="item.path" *ngIf="!hasItems(item)"
            [attr.data-parent]="item.parentId" (click)="getMenu($event)">
            <i class="bx {{item.menuicon}}" ></i>
            <span class="link-title"> {{ item.menuname }}</span>
            <span class="badge badge-{{item.badge.variant}}" *ngIf="item.badge">{{item.badge.text}} 444</span>
          </a>

          <ul *ngIf="hasItems(item)" class="list-unstyled" aria-expanded="false">
            <li class="nav-item" *ngFor="let subitem of item.subMenuList"
              [ngClass]="{'side-nav-item': hasItems(subitem)}" #submenuNav>
              <a class="nav-link side-nav-link-a-ref" *ngIf="hasItems(subitem)" href="javascript:void(0);"
              (click)="getMenu($event)">
                <i class="bx {{item.menuicon}}" ></i>
                <span class="link-title"> {{ subitem.submenuname }}</span>
                <i class="fas fa-angle-down"></i>
              </a>
              <a class="nav-link nav-link-ref" [routerLink]="subitem.path" *ngIf="!hasItems(subitem)"
                [attr.data-parent]="subitem.parentId" (click)="getMenu($event)">
                <!-- <i class="bx bx-git-compare" ></i> -->
                <span class="link-submenu-title"> {{ subitem.submenuname }}</span>
              </a>
            </li>
          </ul>

        </li>
      </ng-container>
    </ul>
  </div>
</ng-template>
<!-- Sidebar -->
<div class="vertical-menu">
  <ngx-simplebar class="h-100" #componentRef>
    <div *ngIf="!isCondensed">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
    <div *ngIf="isCondensed">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
  </ngx-simplebar>
</div>
<!-- Left Sidebar End -->