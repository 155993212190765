import { Component, OnInit } from '@angular/core';
import { CommonComponentsService } from 'src/app/shared/common/components/common-components.service';
import { CommonService } from 'src/app/shared/common/services/common.service';
import { ChartType } from '../kiosk-view'
import { ActivatedRoute, Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { group } from 'console';
import { element } from 'protractor';
@Component({
  selector: 'app-kiosk-static-view',
  templateUrl: './kiosk-static-view.component.html',
  styleUrls: ['./kiosk-static-view.component.scss']
})
export class KioskStaticViewComponent implements OnInit {
  optionsView1: ChartType;
  optionsView2: ChartType;
  optionsView3: ChartType;
  optionsView4: ChartType;
  deviceData: any = [];
  deviceArrayList: any = [];
  deviceArrayDataList: any = [];
  kioskviewData: any = [];
  kioskviewMetrixData: any = [];
  outdoorKioskviewMetrixData: any = [];
  indoorDeviceDataArray: any = [];
  outdoorDeviceDataArray: any = [];
  kioskviewid: number = 0;
  battery_level: any = [];
  co2: any = [];
  humidity: any = [];
  pm10: any = [];
  pm25: any = [];
  temperature: any = [];
  tvoc_sgp: any = [];
  loadUI = false;
  qrlink = window.location.href;
  overAllGraph: any = {};
  outdoorGraphData: any = {};
  indoorGraphData: any = {};
  overAllGraphOutdoor: boolean = false;
  overAllGraphIndoor: boolean = false;
  locationName: any;
  updateSubscription: Subscription;
  DeviceTypeID: any;
  kioskviewMetrixDataSmall: any;
  kioskviewMetrixDataSplit: any = [];
  overAllGraphIndoorV2: boolean;
  overAllGraphV2: any;
  offlineMessage: boolean = false;
  overAllGraphV1: any;
  deviceTypeData: any = [];
  loadDataV2: boolean = false;
  kioskviewDataGroup: any = [];
  kioskviewGrouplist: any = [];
  kioskviewGrouplistOld: any = [];
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private commonComponentsService: CommonComponentsService,
  ) { }
  alertMetricMasterList = {
    'battery_level': "battery_level",
    'rco2': "co2",
    'rhumid': "humidity",
    'rpm10c': "pm10",
    'rpm25c': "pm25",
    'rtemp': "temperature",
    'rtvoc': "tvoc-sgp",
    'aqi': "aqi",
  };
  graphMaxValue = {
    'battery_level': "100",
    'co2': "5000",
    'humidity': "100",
    'pm10': "424",
    'pm25': "250",
    'temperature': "90",
    'tvoc-sgp': "4500",
    'aqi': "500",
    'o3': "200"
  };
  graphMaxValueV2 = {
    "CO2_ppm": "5000",
    "Humd": "100",
    "MoldIndex": "100",
    "O3_ppm": "200",
    "TVOCs_ppb": "4500",
    "Temp_C": "90",
    "aqi": "500",
    "PC0.1": "",
    "PC0.3": "",
    "PC0.5": "",
    "PC10": "",
    "PC1.0": "",
    "PC2.5": "",
    "PC5": "",
    "PM0.1_ug/m3": "250",
    "PM0.3_ug/m3": "250",
    "PM0.5_ug/m3": "250",
    "PM10_ug/m3": "424",
    "PM1.0_ug/m3": "250",
    "PM2.5_ug/m3": "250",
    "PM5_ug/m3": "424"
  }
  masterMatricForFlair2 = {
    "CO2_ppm": "co2",
    "Humd": "humidity",
    "MoldIndex": "MoldIndex",
    "O3_ppm": "o3",
    "TVOCs_ppb": "tvoc-sgp",
    "Temp_C": "temperature",
    "AQI": "aqi",
    "PC0.1": "PC0.1",
    "PC0.3": "PC0.3",
    "PC0.5": "PC0.5",
    "PC10": "PC10",
    "PC1.0": "PC1.0",
    "PC2.5": "PC2.5",
    "PC5": "PC5",
    "PM0.1_ug/m3": "PM0.1_ug/m3",
    "PM0.3_ug/m3": "PM0.3_ug/m3",
    "PM0.5_ug/m3": "PM0.5_ug/m3",
    "PM10_ug/m3": "pm10",
    "PM1.0_ug/m3": "PM1.0_ug/m3",
    "PM2.5_ug/m3": "pm25",
    "PM5_ug/m3": "PM5_ug/m3"
  };
  conceptMasterMatricForFlair2 = {
    "CO_ppm":"CO_ppm",
    "Status" : "Status",
    "NO2_ppb":"NO2_ppb",
    "CO2_ppm": "co2",
    "Humd": "humidity",
    "MoldIndex": "Mold Index",
    "O3_ppm": "o3",
    "TVOCs_ppb": "tvoc-sgp",
    "Temp_C": "temperature",
    "AQI": "aqi",
    "PC0.1": "PC 0.1",
    "PC0.3": "PC 0.3",
    "PC0.5": "PC 0.5",
    "PC10": "PC 10",
    "PC1.0": "PC 1.0",
    "PC2.5": "PC 2.5",
    "PC5": "PC 5",
    "PM0.1_ug/m3": "pm25",
    "PM0.3_ug/m3": "pm25",
    "PM0.5_ug/m3": "pm25",
    "PM10_ug/m3": "pm10",
    "PM1.0_ug/m3": "pm25",
    "PM2.5_ug/m3": "pm25",
    "PM5_ug/m3": "pm10"
  };
  masterMatricForFlair2Unit = {
    "CO2_ppm": "ppm",
    "Humd": "%",
    "MoldIndex": ".",
    "O3_ppm": "ppb",
    "TVOCs_ppb": "ppb",
    "Temp_C": "F",
    "AQI": "US",
    "PC0.1": "Count",
    "PC0.3": "Count",
    "PC0.5": "Count",
    "PC10": "Count",
    "PC1.0": "Count",
    "PC2.5": "Count",
    "PC5": "Count",
    "PM0.1_ug/m3": "µg/m³",
    "PM0.3_ug/m3": "µg/m³",
    "PM0.5_ug/m3": "µg/m³",
    "PM10_ug/m3": "µg/m³",
    "PM1.0_ug/m3": "µg/m³",
    "PM2.5_ug/m3": "µg/m³",
    "PM5_ug/m3": "µg/m³"
  };
  masterMatricGroup = {
    "aqi": "AQI",
    "rco2": "CO2_ppm",
    "rtvoc": "TVOCs_ppb",
    "rhumid": "Humd",
    "rtemp": "Temp_C",
    "battery_level": "Battery",
    "O3_ppm": "O3_ppm",
    "MoldIndex": "MoldIndex",
    "PC0.1": "PC0.1",
    "PC0.5": "PC0.5",
    "PC1.0": "PC1.0",
    "PC2.5": "PC2.5",
    "PC5": "PC5",
    "PC10": "PC10",
    "PM0.1_ug/m3": "PM0.1_ug/m3",
    "PM0.3_ug/m3": "PM0.3_ug/m3",
    "PM0.5_ug/m3": "PM0.5_ug/m3",
    "PM1.0_ug/m3": "PM1.0_ug/m3",
    "rpm25c": "PM2.5_ug/m3",
    "rpm10c": "PM10_ug/m3"
  }
  flair2Matric: any = ["CO2_ppm", "Humd", "MoldIndex", "O3_ppm", "TVOCs_ppb", "Temp_C", "AQI", "PC0.1", "PC0.3", "PC0.5", "PC10", "PC1.0", "PC2.5", "PC5", "PM0.1_ug/m3", "PM0.3_ug/m3", "PM0.5_ug/m3", "PM10_ug/m3", "PM1.0_ug/m3", "PM2.5_ug/m3", "PM5_ug/m3"];
  staticOutdoorArray = ['baqi', 'o3', 'temp', 'humidity', 'pm10', 'pm25'];
  ppbExists = false;
  ppbValue = 0;

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.kioskviewid = Number(atob((params['id']).toString()))
      if (this.kioskviewid) {
        this.getKioskViewDetail(this.kioskviewid);
        this.getKioskViewMetrix(this.kioskviewid);
        this.getKioskViewGroup();
      }
    })
  }

  loadData() {
    return new Promise((resolve, reject) => {
      let date = new Date();
      date.setDate(date.getMinutes() - 5);
      let startDate = date.toISOString();
      let endDate = new Date().toISOString();
      let deviceId = "9f61a18f-90f2-4fc8-acff-2f1a721c69fd"
      let isFlair1 = true
      let params = `deviceId=${deviceId}&startDate=${startDate}&endDate=${endDate}&isFlair1=${isFlair1}&isv2=false&groupby=1m&timezone=America/New_York`;
      this.commonService.getWhioutLoader('Device/GetDeviceTelemetry?' + params).subscribe((response: any) => {
        let data: any = response.data;

      }, error => {
        reject(error);
        this.commonComponentsService.openAlert("Error!", 'Error in getting Device Telemetry');
      });
    });
  }
  processData() {
    this.deviceData.forEach(element => {
      this.indoorDeviceDataArray[element['param']] = element;
      this.outdoorDeviceDataArray[element['param']] = element;
    });
  }
  outdoorLoadOverAllGraph(outdoorValue) {
    this.outdoorGraphData = outdoorValue;
    this.outdoorGraphData['chartColor'] = '';
    let matiric = outdoorValue != undefined ? outdoorValue['outdoormetricvalue'] == 'baqi' ? 'aqi' : outdoorValue['outdoormetricvalue'] : [];
    this.outdoorGraphData['chartColor'] = this.getChartClass(matiric, outdoorValue != undefined ? outdoorValue.value : "");
    this.outdoorGraphData['chartBackground'] = this.getChartBackgroundClass(matiric, outdoorValue.value);
    this.overAllGraphOutdoor = true;
    if (outdoorValue) {
      this.optionsView2 = {
        chart: {
          height: 210,
          type: "radialBar",
        },
        series: [this.valueToPercent(outdoorValue.value, this.graphMaxValue[matiric])],
        colors: [this.outdoorGraphData['chartColor']],
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            hollow: {
              margin: 15,
              size: "70%"
            },

            track: {
              background: '#fff',
              startAngle: -135,
              endAngle: 135,
            },
            dataLabels: {
              name: {
                show: false,
              },

              value: {
                fontSize: "46px",
                fontWeight: "500",
                show: true,
                formatter: function (val) {
                  return outdoorValue.value;
                }
              }
            }
          }
        },
        fill: {
          type: "solid",
          colors: ['#2DCD7A']
        },
        stroke: {
          lineCap: "round"
        },
        labels: ["Progress"]
      };
    }
  }

  processCSS() {
    let CSS = "top-border";
    return CSS;
  }


  kioskviewMetrixdeviceArrayList() {
    let temArray = {}
    let metrixDataCount = 0;
    this.kioskviewMetrixData.forEach(element => {
      if (element.deviceidarray) {
        temArray = element.deviceidarray.split(',');
        element.deviceidarray = temArray;
        this.deviceArrayList = this.deviceArrayList.concat(temArray)
        this.deviceArrayList = [...new Set(this.deviceArrayList)];
      }
      if (element.location) {
        this.outdoorKioskviewMetrixData[metrixDataCount] = {
          "location": element.location,
          "widgetsize": element.widgetsize,
          "outdoormetricname": element.outdoormetricname,
          "outdoormetricunit": element.outdoormetricunit,
          "outdoormetricvalue": element.outdoormetricvalue
        };
        this.getOutdoorPlaceData(metrixDataCount, element.location);
        metrixDataCount++;
      }
    });
    this.kioskviewMetrixdeviceArrayListData();
    this.validedkioskviewMetrixdeviceArray(1);
  }
  kioskviewMetrixdeviceArrayListData() {
    let deviceIds = [];
    this.deviceArrayList.forEach(element => {
      this.getDeviceTypeID(element);
      let deviceIds2 = {
        data: element
      }
      deviceIds.push(deviceIds2.data);
    });
    // if (deviceIds) {
    this.getDeviceData(deviceIds);
    // }
    setTimeout(() => { if (this.loadDataV2 == false) { this.kioskviewMetrixdeviceArrayListData(); this.deviceArrayDataList = []; this.loadDataV2 = true } }, 2000)
  }
  validedkioskviewMetrixdeviceArray(intervalTime) {
    var keys = Object.keys(this.deviceArrayDataList);
    if (intervalTime == 3) {
      intervalTime = 1;
      this.updateSubscription = interval(30000).subscribe(
        (val) => {
          setTimeout(() => {
            this.kioskviewMetrixdeviceArrayListData();
            this.getKioskViewGroup();
            this.deviceArrayDataList = []
          }, 3000)
        });
      setTimeout(() => { this.kioskviewMetrixdeviceArrayListData(); }, 500);
    } else {
      setTimeout(() => { this.validedkioskviewMetrixdeviceArray(intervalTime + 1) }, 3000)
    }

  }
  processKiosViewData() {
    let demoData = [];
    this.kioskviewMetrixData.forEach(element => {
      let matricName = element.devicetypeid == 1 ? this.alertMetricMasterList[element.metricnamekaiterra] : element.metricnamekaiterra;
      element.data = {
        'name': element.metricname,
        'unit': "",
        'average': "",
        'color': "",
        'CSS': "",
        'percent': "",
        'maxNumber': "",
        'matricName': matricName
      };
      element.deviceidarrayData = [];
      element.deviceidarrayDataAverage = 0
      if (element.devicetypeid == 1) {
        if (typeof element.deviceidarray === "object" && matricName) {
          element.deviceidarray.forEach(DID => {
            if (this.deviceArrayDataList[DID] !== undefined) {
              if (this.deviceArrayDataList[DID][matricName] !== undefined && element.data.name != "AQI") {
                let deviceLength = this.deviceArrayDataList[DID][matricName]['points'].length;
                element.deviceidarrayData.push(this.deviceArrayDataList[DID][matricName]['points'][deviceLength - 1]['value']);
                element.data.unit = matricName == 'battery_level' ? "%" : this.deviceArrayDataList[DID][matricName]['units'];
              }
              if (this.deviceArrayDataList[DID][matricName] !== undefined && element.data.name == "AQI") {
                let deviceLength = this.deviceArrayDataList[DID][matricName]['points'].length;
                // element.deviceidarrayData.push(this.deviceArrayDataList[DID][matricName]['points'][deviceLength - 1]['aqi']);
                element.deviceidarrayData.push(this.deviceArrayDataList[DID][matricName]['points'][deviceLength - 1]['value']);
                element.data.unit = "US";
                matricName = "aqi";
                element.data.name = "Overall Index";
              }
              // count++;
            } else {

            }
          });
        }
        let unit = element.data.unit;
        if (!unit) {
          unit = (this.masterMatricForFlair2Unit[element.metricnamekaiterra]) ? this.masterMatricForFlair2Unit[element.metricnamekaiterra] : this.masterMatricForFlair2Unit[element.metricname];
          unit = (unit) ? unit : '';
          element.data.unit = matricName == 'battery_level' ? "%" : unit;
        }
        element.data.average = Number.isNaN(Math.round(element.deviceidarrayData.reduce((a, b) => a + b, 0) / element.deviceidarrayData.length)) ? 'NA' : Math.round(element.deviceidarrayData.reduce((a, b) => a + b, 0) / element.deviceidarrayData.length);
        element.data.CSS = "halftop-border-green halfright-border-green";
        element.data.color = this.getWidgetColor(matricName, element.data.average);
        element.data.maxNumber = this.getTelematryMaxValue(element.data.color, matricName);
        element.data.percent = this.getPercentage(element.data.average, element.data.maxNumber);
        element.data.CSS = this.getProgressBarCSS(element.data.color, element.data.percent);
        element.data.chartColor = this.getChartClass(matricName, element.data.average);
        if (element.widgetsize === 3 && element.location === "") {
          element.data.chartBackground = this.getChartBackgroundClass(matricName, element.data.average);
          // this.loadOverAllGraph(element.data);
          element.data.devcieName = element.deviceName;
          this.overAllGraph = element.data;
        }
      }
      if (element.devicetypeid == 2 || element.devicetypeid == 4) {
        if (typeof element.deviceidarray === "object" && matricName) {
          element.deviceidarray.forEach(DID => {
            if (this.deviceArrayDataList[DID] !== undefined) {
              if (this.deviceArrayDataList[DID][matricName] !== undefined && matricName != "Temp_C") {
                element.deviceidarrayData.push(this.deviceArrayDataList[DID][matricName]);
              }
              if (this.deviceArrayDataList[DID][matricName] !== undefined && matricName == "Temp_C") {
                element.deviceidarrayData.push(this.convertTemp(this.deviceArrayDataList[DID][matricName]));
              }
            }
          });
        }
        if (matricName == "AQI") {
          element.data.matricName = "AQI";
          element.data.name = "Overall Index";
        }
        element.data.unit = this.masterMatricForFlair2Unit[matricName];
        let averageData = element.deviceidarrayData.reduce((a, b) => a + b, 0) == 0 ? element.deviceidarrayData.reduce((a, b) => a + b, 0) : Math.round(element.deviceidarrayData.reduce((a, b) => a + b, 0) / element.deviceidarrayData.length)
        element.data.average = averageData;
        element.data.color = this.getWidgetColor(this.conceptMasterMatricForFlair2[matricName], element.data.average);
        element.data.maxNumber = this.getTelematryMaxValue(element.data.color, this.conceptMasterMatricForFlair2[matricName]);
        element.data.percent = this.getPercentage(element.data.average, element.data.maxNumber);
        element.data.CSS = this.conceptMasterMatricForFlair2[matricName] == "null" ? " " : this.getProgressBarCSS(element.data.color, element.data.percent);
        element.data.chartColor = this.getChartClass(matricName == "aqi" ? "aqi" : this.conceptMasterMatricForFlair2[matricName], element.data.average);
        element.data.deviceTypeId = element.devicetypeid;
      }
    });
    // if (this.overAllGraph) {
    //   this.loadUI = true;
    // }
    // if (this.DeviceTypeID == 2) {
    // this.getFormatedMatrices();
    // }
    // this.loadUI = true;
  }
  processKiosViewDataNew() {
    let demoData = [];
    this.kioskviewMetrixData.forEach(element => {
      let matricName = element.devicetypeid == 1 ? this.alertMetricMasterList[element.metricnamekaiterra] : element.metricnamekaiterra;
      element.data = {
        'name': element.metricname,
        'unit': "",
        'average': "",
        'color': "",
        'CSS': "",
        'percent': "",
        'maxNumber': "",
        'matricName': matricName
      };
      element.deviceidarrayData = [];
      element.deviceidarrayDataAverage = 0
      if (element.devicetypeid == 1) {
        if (typeof element.deviceidarray === "object" && matricName) {
          element.deviceidarray.forEach(DID => {

            if (this.deviceArrayDataList[DID] !== undefined) {
              if (this.deviceArrayDataList[DID][matricName] !== undefined && element.data.name != "AQI") {
                let deviceLength = this.deviceArrayDataList[DID][matricName]['points'].length;
                element.deviceidarrayData.push(this.deviceArrayDataList[DID][matricName]['points'][deviceLength - 1]['value']);
                element.data.unit = matricName == 'battery_level' ? "%" : this.deviceArrayDataList[DID][matricName]['units'];
              }
              if (this.deviceArrayDataList[DID][matricName] !== undefined && element.data.name == "AQI") {
                let deviceLength = this.deviceArrayDataList[DID][matricName]['points'].length;
                // element.deviceidarrayData.push(this.deviceArrayDataList[DID][matricName]['points'][deviceLength - 1]['aqi']);
                element.deviceidarrayData.push(this.deviceArrayDataList[DID][matricName]['points'][deviceLength - 1]['value']);
                element.data.unit = "US";
                matricName = "aqi";
                element.data.name = "Overall Index";
              }
              // count++;
            } else {

            }
          });
        }
        let unit = element.data.unit;
        if (!unit) {
          unit = (this.masterMatricForFlair2Unit[element.metricnamekaiterra]) ? this.masterMatricForFlair2Unit[element.metricnamekaiterra] : this.masterMatricForFlair2Unit[element.metricname];
          unit = (unit) ? unit : '';
          element.data.unit = matricName == 'battery_level' ? "%" : unit;
        }
        element.data.average = Number.isNaN(Math.round(element.deviceidarrayData.reduce((a, b) => a + b, 0) / element.deviceidarrayData.length)) ? 'NA' : Math.round(element.deviceidarrayData.reduce((a, b) => a + b, 0) / element.deviceidarrayData.length);
        element.data.CSS = "halftop-border-green halfright-border-green";
        element.data.color = this.getWidgetColor(matricName, element.data.average);
        element.data.maxNumber = this.getTelematryMaxValue(element.data.color, matricName);
        element.data.percent = this.getPercentage(element.data.average, element.data.maxNumber);
        element.data.CSS = this.getProgressBarCSS(element.data.color, element.data.percent);
        element.data.chartColor = this.getChartClass(matricName, element.data.average);
        if (element.widgetsize === 3 && element.location === "") {
          element.data.chartBackground = this.getChartBackgroundClass(matricName, element.data.average);
          // this.loadOverAllGraph(element.data);
          element.data.devcieName = element.deviceName;
          this.overAllGraph = element.data;

        }
      }
      if (element.devicetypeid == 2 || element.devicetypeid == 4) {
        if (typeof element.deviceidarray === "object" && matricName) {
          element.deviceidarray.forEach(DID => {
            if (this.deviceArrayDataList[DID] !== undefined) {
              if (this.deviceArrayDataList[DID][matricName] !== undefined && matricName != "Temp_C") {
                element.deviceidarrayData.push(this.deviceArrayDataList[DID][matricName]);
              }
              if (this.deviceArrayDataList[DID][matricName] !== undefined && matricName == "Temp_C") {
                element.deviceidarrayData.push(this.convertTemp(this.deviceArrayDataList[DID][matricName]));
              }
            }
          });
        }
        if (matricName == "AQI") {
          element.data.matricName = "AQI";
          element.data.name = "Overall Index";
        }
        element.data.unit = this.masterMatricForFlair2Unit[matricName];
        let averageData = element.deviceidarrayData.reduce((a, b) => a + b, 0) == 0 ? element.deviceidarrayData.reduce((a, b) => a + b, 0) : Math.round(element.deviceidarrayData.reduce((a, b) => a + b, 0) / element.deviceidarrayData.length)
        element.data.average = averageData;
        element.data.color = this.getWidgetColor(this.conceptMasterMatricForFlair2[matricName], element.data.average);
        element.data.maxNumber = this.getTelematryMaxValue(element.data.color, this.conceptMasterMatricForFlair2[matricName]);
        element.data.percent = this.getPercentage(element.data.average, element.data.maxNumber);
        element.data.CSS = this.conceptMasterMatricForFlair2[matricName] == "null" ? " " : this.getProgressBarCSS(element.data.color, element.data.percent);
        element.data.chartColor = this.getChartClass(matricName == "aqi" ? "aqi" : this.conceptMasterMatricForFlair2[matricName], element.data.average);
        element.data.deviceTypeId = element.devicetypeid;
      }
    });
    // if (this.overAllGraph) {
    //   this.loadUI = true;
    // }
    // if (this.DeviceTypeID == 2) {
    // this.getFormatedMatrices();
    // }
    // this.loadUI = true;
  }

  //=========================================== CRUD Functionality Start

  getKioskViewDetail(kioskviewid) {

    let params = this.kioskviewid;
    return new Promise((resolve, reject) => {
      this.commonService.getWhioutLoader('KioskView/KioskView_PublicAPI?pageSize=500&pageNumber=0&kioskviewid=' + params).subscribe((response: any) => {
        let data: any = response;
        this.kioskviewData = data['data']['kioskViewList'][0];
        // this.kioskviewData.backgroundimagepath = null;
        this.kioskviewData.backgroundimagepath = this.kioskviewData.backgroundimagepath == '' ? "https://thinkliteairdtsa.blob.core.windows.net/thinklite-files/Kioskview/KioskDefaultImage1.png" : this.kioskviewData.backgroundimagepath;
      }, error => {
        reject(error);
        this.commonComponentsService.openAlert("Error!", 'Error in getting Customer');
      });
    });

  }
  getKioskViewMetrix(kioskviewid) {
    let params = this.kioskviewid;
    return new Promise((resolve, reject) => {
      this.commonService.getWhioutLoader('KioskViewMatrics/KioskViewMatrics_PublicAPI?pageSize=500&pageNumber=0&kioskviewid=' + params).subscribe((response: any) => {
        let data: any = response;
        this.kioskviewMetrixData = data['data']['kioskViewMatricsList'];
        this.kioskviewMetrixdeviceArrayList();
        this.getLocationNameData();
      }, error => {
        reject(error);
        this.commonComponentsService.openAlert("Error!", 'Error in getting Customer');
      });
    });
  }
  getDeviceData(deviceIds) {
    return new Promise((resolve, reject) => {
      deviceIds.forEach(deviceId => {
        this.getDeviceTypeID(deviceId);
        let date = new Date();
        date.setMinutes(date.getMinutes() - 5);
        let startDate = date.toISOString();
        let endDate = new Date().toISOString();
        //Added condition for FLair2.0 Outdoor Device(deviceId==4) check, under Task:7236, added by Amit Singh.
        let isFlair1 = (this.deviceTypeData[deviceId] == 2 || this.deviceTypeData[deviceId] == 4) ? "&isFlair1=false" : "&isFlair1=true";
        let params = `startDate=${startDate}&endDate=${endDate}&isv2=false&groupby=1m&timezone=America/New_York&deviceId=${deviceId}${isFlair1}`;
        this.commonService.getWhioutLoader('Device/GetDeviceTelemetry_PublicAPI?' + params).subscribe((response: any) => {
          let data: any = JSON.parse(response.data);
          
          if (this.deviceTypeData[deviceId] == 1) {
            data.forEach(element => {
              this.deviceArrayDataList[element.Item1] = {}
              let data2 = JSON.parse(element.Item2);
              data2.forEach(data3 => {
                if (data3['param'] !== undefined) {
                  this.deviceArrayDataList[element.Item1][data3['param']] = data3;
                  if (data3['param'] == 'tvoc-sgp') {
                    let objParam = { param: 'aqi', points: data3.points, source: data3.source, span: data3.span, units: data3.units };
                    this.deviceArrayDataList[element.Item1][objParam['param']] = objParam;
                  }
                }
              });
            });
          }
          if (data.length == 0) {
            this.offlineMessage = true;
          }
          if (this.deviceTypeData[deviceId] == 2 && data.length == 0) {
            this.offlineMessage = true;
            const date = new Date();
                  date.setMonth(date.getHours() - 24);
                  var startDate = date.toISOString();
                  const    endDate = new Date().toISOString();
                  let ApiLink = 'Device/GetDeviceTelemetryV2_PublicApi?deviceId=' + deviceId + '&startDate=' + startDate + '&endDate=' + endDate + '&groupBy=1d&timeZone=America/New_York&isFlair1=false';
                  this.commonService.getWhioutLoader(ApiLink).subscribe(async ( response: any) => {
                      const {  data = [] } = response;
                      if(data!=null){
                      var maxCreatedDateData = data.reduce((max, current) => 
                                new Date(max.createdDate) > new Date(current.createdDate) ? max : current
                            );
                      if (maxCreatedDateData) {
                        this.deviceArrayDataList[deviceId] = {}
                        this.offlineMessage = true;
                        var deviceTelemetryJson = {
                          CO2_ppm: maxCreatedDateData.cO2_ppm,
                          Humd: maxCreatedDateData.humd,
                          IsBacklog: null,
                          MoldIndex: maxCreatedDateData.moldIndex,
                          O3_ppb: maxCreatedDateData.o3_ppb,
                          TVOCs_ppb: maxCreatedDateData.tvoCs_ppb,
                          Temp_C: ((maxCreatedDateData.temp_C-32)*5)/9,
                          AQI: maxCreatedDateData.aqi,
                          PC0_1: maxCreatedDateData.pC0_1,
                          PC0_3: maxCreatedDateData.pC0_3,
                          PC0_5: maxCreatedDateData.pC0_5,
                          PC10: maxCreatedDateData.pC10,
                          PC1_0: maxCreatedDateData.pC1_0,
                          PC2_5: maxCreatedDateData.pC2_5,
                          PC5: maxCreatedDateData.pC5,
                          PM0_1_ug_m3: maxCreatedDateData.pM0_1_ug_m3,
                          PM0_3_ug_m3: maxCreatedDateData.pM0_3_ug_m3,
                          PM0_5_ug_m3: maxCreatedDateData.pM0_5_ug_m3,
                          PM10_ug_m3: maxCreatedDateData.pM10_ug_m3,
                          PM1_0_ug_m3: maxCreatedDateData.pM1_0_ug_m3,
                          PM2_5_ug_m3: maxCreatedDateData.pM2_5_ug_m3,
                          PM5_ug_m3: maxCreatedDateData.pM5_ug_m3,
                          AirPressure_pa: maxCreatedDateData.airPressure_pa,
                          CO_ppm: maxCreatedDateData.cO_ppm,
                          NO2_ppb: maxCreatedDateData.nO2_ppb,
                        };
                        this.deviceArrayDataList[data[0].DeviceId] = {}
                        let data2 = deviceTelemetryJson;
                        
                         
                      Object.entries(data2).forEach(entry => {
                        const [key, value] = entry;
                        if (key == 'O3_ppb') {
                          this.ppbExists = true;
                          this.ppbValue = data2[key];
                        } 
                       });


                        this.flair2Matric.forEach(element => {

                        if (this.ppbExists == true && element == 'O3_ppm')
                          element = 'O3_ppb';
                        this.deviceArrayDataList[deviceId][element] = data2[element];
                        });
                        this.processKiosViewData();
                      }
                      }
                      
                  }, error => {
                    this.commonComponentsService.openAlert("Error!", 'Error in getting Channel Partner');
                  });
          }
          else{
          if (this.deviceTypeData[deviceId] == 2 && data.length != 0) {
            this.deviceArrayDataList[data[0].DeviceId] = {}
            let data2 = [];
            if (data[0].DeviceTelemetryJson != undefined) {
              data2 = JSON.parse(data[0].DeviceTelemetryJson);
            }
            Object.entries(data2).forEach(entry => {
              const [key, value] = entry;
              if (key == 'O3_ppb') {
                this.ppbExists = true;
                this.ppbValue = data2[key];
              }
            });

            this.flair2Matric.forEach(element => {
              if (this.ppbExists == true && element == 'O3_ppm')
                element = 'O3_ppb';
              this.deviceArrayDataList[data[0].DeviceId][element] = data2[element];
            });

          }
        }
          // 15-Mar-2023
          this.processKiosViewData();
        }, error => {
          reject(error);
          this.commonComponentsService.openAlert("Error!", 'Error in getting Project');
          return {};
        });
      });
      this.processKiosViewData();
    });
  }
  //=========================================== CRUD Functionality End

  // ========================================== CSS functionality Start
  getProgressBarCSS(color, percent) {
    // if(color == null || percent == null) { return ""; }
    if (percent >= 0 && percent <= 5) { return 'top-border-' + color + '-20'; }
    if (percent >= 5.01 && percent <= 10) { return 'top-border-' + color + '-20 right-border-' + color + '-20'; }
    if (percent >= 10.01 && percent <= 15) { return 'top-border-' + color + '-20 right-border-' + color + '-40'; }
    if (percent >= 15.01 && percent <= 20) { return 'top-border-' + color + '-20 right-border-' + color + '-60'; }
    if (percent >= 20.01 && percent <= 25) { return 'top-border-' + color + '-20 right-border-' + color + '-80'; }
    if (percent >= 25.01 && percent <= 30) { return 'top-border-' + color + '-20 right-border-' + color + '-100'; }
    if (percent >= 30.01 && percent <= 35) { return 'top-border-' + color + '-20 right-border-' + color + '-100 bottom-border-' + color + '-20'; }
    if (percent >= 35.01 && percent <= 40) { return 'top-border-' + color + '-20 right-border-' + color + '-100 bottom-border-' + color + '-40'; }
    if (percent >= 40.01 && percent <= 45) { return 'top-border-' + color + '-20 right-border-' + color + '-100 bottom-border-' + color + '-60'; }
    if (percent >= 45.01 && percent <= 50) { return 'top-border-' + color + '-20 right-border-' + color + '-100 bottom-border-' + color + '-80'; }
    if (percent >= 50.01 && percent <= 55) { return 'top-border-' + color + '-20 right-border-' + color + '-100 bottom-border-' + color + '-100'; }
    if (percent >= 55.01 && percent <= 60) { return 'top-border-' + color + '-20 right-border-' + color + '-100 bottom-border-' + color + '-100 left-border-' + color + '-100'; }
    if (percent >= 60.01 && percent <= 65) { return 'top-border-' + color + '-20 right-border-' + color + '-100 bottom-border-' + color + '-100 left-border-' + color + '-100'; }
    if (percent >= 65.01 && percent <= 70) { return 'top-border-' + color + '-20 right-border-' + color + '-100 bottom-border-' + color + '-100 left-border-' + color + '-100'; }
    if (percent >= 70.01 && percent <= 75) { return 'top-border-' + color + '-20 right-border-' + color + '-100 bottom-border-' + color + '-100 left-border-' + color + '-100'; }
    if (percent >= 75.01 && percent <= 80) { return 'top-border-' + color + '-20 right-border-' + color + '-100 bottom-border-' + color + '-100 left-border-' + color + '-100'; }
    if (percent >= 80.01 && percent <= 85) { return 'top-border-' + color + '-40 right-border-' + color + '-100 bottom-border-' + color + '-100 left-border-' + color + '-100'; }
    if (percent >= 85.01 && percent <= 90) { return 'top-border-' + color + '-60 right-border-' + color + '-100 bottom-border-' + color + '-100 left-border-' + color + '-100'; }
    if (percent >= 90.01 && percent <= 95) { return 'top-border-' + color + '-80 right-border-' + color + '-100 bottom-border-' + color + '-100 left-border-' + color + '-100'; }
    if (percent >= 95.01 && percent <= 100) { return 'top-border-' + color + '-100 right-border-' + color + '-100 bottom-border-' + color + '-100 left-border-' + color + '-100'; }
  }

  getWidgetColor(param, value) {
    if(value==undefined) value=0;
    value = Math.round(value);
    if ((param == "temperature" && (value >= 64 && value <= 77)) || (param == "co2" && value >= 400 && value <= 800) || (param == "humidity" && value >= 40 && value <= 50) || (param == "pm10" && value >= 0 && value <= 54) || (param == "pm25" && value >= 0 && value <= 12.0) || (param == "tvoc-sgp" && value >= 0 && value <= 220) || (param == "aqi" && (value >= 0 && value <= 50)) || (param == "o3" && (value >= 0 && value <= 54))
    || (param == "AirPressure" && (value >= 950 && value <= 1040))
    || (param == "PC0.1" && (value >= 0 && value <= 2000000))
    || (param == "PC0.3" && (value >= 0 && value <= 100000))
    || (param == "PC0.5" && (value >= 0 && value <= 35000))
    || (param == "PC1.0" && (value >= 0 && value <= 3500))
    || (param == "PC2.5" && (value >= 0 && value <= 750))
    || (param == "PC5" && (value >= 0 && value <= 150))
    || (param == "PC10" && (value >= 0 && value <= 50))
    || (param == "MoldIndex" && (value >= 0 && value <= 50))
    || ((param == "O3_ppm" || param == "O3_ppb")  && (value >= 0 && value <= 54)) 
    || ((param == "pm01" || param == "pm03" || param == "pm05"  || param == "pm010" )  && (value >35 && value <= 55)) 
    ) {
      return "green";
    }
    if ((param == "temperature" && ((value >= 62 && value <= 63) || (value >= 78 && value <= 79))) || (param == "co2" && value >= 801 && value <= 1500) || (param == "humidity" && ((value >= 35 && value <= 39) || (value >= 51 && value <= 60))) || (param == "pm10" && value >= 54.1 && value <= 154) || (param == "pm25" && value >= 12.1 && value <= 35.4) || (param == "tvoc-sgp" && value >= 221 && value <= 660) || (param == "aqi" && (value >= 50.1 && value <= 100)) || (param == "o3" && (value >= 55 && value <= 70))
    || (param == "AirPressure" && (value >= 600 && value <= 949)) ||  (param == "AirPressure" && (value >= 1041 && value <= 1100))
    || (param == "PC0.1" && (value >= 2000001 && value <= 3500000))
    || (param == "PC0.3" && (value >= 100001 && value <= 250000))
    || (param == "PC0.5" && (value >= 35001 && value <= 70000))
    || (param == "PC1.0" && (value >= 3501 && value <= 7000))
    || (param == "PC2.5" && (value >= 751 && value <= 1500))
    || (param == "PC5" && (value >= 151 && value <= 250))
    || (param == "PC10" && (value >= 51 && value <= 150))
    || ((param == "O3_ppm" || param == "O3_ppb")  && (value >= 55 && value <= 70))
    || ((param == "pm01" || param == "pm03" || param == "pm05"  || param == "pm010" )  && (value >12 && value <= 35)) 
    ) {
      return "yellow";
    }
    if ((param == "temperature" && ((value >= 51 && value <= 61) || (value >= 80 && value <= 89))) || (param == "co2" && value >= 1501 && value <= 2000) || (param == "humidity" && ((value >= 20 && value <= 34) || (value >= 61 && value <= 64))) || (param == "pm10" && value >= 154.1 && value <= 254) || (param == "pm25" && value >= 35.5 && value <= 55.4) || (param == "tvoc-sgp" && value >= 661 && value <= 2000) || (param == "aqi" && (value >= 100.1 && value <= 150)) || (param == "o3" && (value >= 71 && value <= 84))
    || (param == "PC0.1" && (value >= 3500001 && value <= 5000000))
    || (param == "PC0.3" && (value >= 250001 && value <= 500000))
    || (param == "PC0.5" && (value >= 70001 && value <= 150000))
    || (param == "PC1.0" && (value >= 7001 && value <= 15000))
    || (param == "PC2.5" && (value >= 1501 && value <= 2000))
    || (param == "PC5" && (value >= 251 && value <= 500))
    || (param == "PC10" && (value >= 151 && value <= 250))
    || (param == "MoldIndex" && (value >= 51 && value <= 75))
    || ((param == "O3_ppm" || param == "O3_ppb")  && (value >= 71 && value <= 85)) 
    ) {
      return "orange";
    }
    if ((param == "temperature" && ((value >= 48 && value <= 50) || (value >= 90 && value <= 92))) || (param == "co2" && value >= 2001 && value <= 2500) || (param == "humidity" && ((value >= 15 && value <= 19) || (value >= 65 && value <= 80))) || (param == "pm10" && value >= 254.1 && value <= 354) || (param == "pm25" && value >= 55.5 && value <= 150.4) || (param == "tvoc-sgp" && value >= 2001 && value <= 3500) || (param == "aqi" && (value >= 150.1 && value <= 200)) || (param == "o3" && (value >= 86 && value <= 105))
    || (param == "AirPressure" && (value >= 0 && value <= 600))
    || (param == "PC0.1" && (value >= 5000001 && value <= 10000000))
    || (param == "PC0.3" && (value >= 500001 && value <= 1000000))
    || (param == "PC0.5" && (value >= 150001 && value <= 350000))
    || (param == "PC1.0" && (value >= 15001 && value <= 35000))
    || (param == "PC2.5" && (value >= 2001 && value <= 2500))
    || (param == "PC5" && (value >= 501 && value <= 750))
    || (param == "PC10" && (value >= 251 && value <= 500))
    || (param == "MoldIndex" && (value >75))
    || ((param == "O3_ppm" || param == "O3_ppb")  && (value >= 86 && value <= 105))
    || ((param == "pm01" || param == "pm03" || param == "pm05"  || param == "pm010" )  && (value >55 && value <= 150)) 
    ) {
      return "red";
    }
    if ((param == "temperature" && ((value <= 48) || (value >= 92))) || (param == "co2" && value >= 2501) || (param == "humidity" && ((value >= 1 && value <= 14) || (value >= 81))) || (param == "pm10" && value >= 354) || (param == "pm25" && value >= 150.5) || (param == "tvoc-sgp" && value >= 3501) || (param == "aqi" && (value >= 200.1 && value <= 300)) || (param == "o3" && (value >= 106 && value <= 200))
    || (param == "AirPressure" && value > 1100 )
    || (param == "PC0.1" && (value > 10000000))
    || (param == "PC0.3" && (value > 1000000))
    || (param == "PC0.5" && (value > 350000))
    || (param == "PC1.0" && (value > 35000))
    || (param == "PC2.5" && (value > 2500))
    || (param == "PC5" && (value > 750))
    || (param == "PC10" && (value > 500))
    || ((param == "O3_ppm" || param == "O3_ppb")  && (value > 105)) 
    || ((param == "pm01" || param == "pm03" || param == "pm05"  || param == "pm010" )  && (value > 150)) 
    ) {
      return "purple";
    }
    if ((param == "aqi" && (value >= 301)) || (param == "o3" && (value >= 200))) {
      return "brown";
    }
    return "green"
  }


  getPercentage(value, maxNumber) {
    return Math.round((value * 100 / maxNumber <= 100) ? value * 100 / maxNumber : 100);
  }
  getTelematryMaxValue(color, param) {
    if (param == 'temperature') { return '100'; }
    if (param == 'humidity') { return '100'; }
    if (param == 'pm10') { return '500'; }
    if (param == 'pm25') { return '250'; }
    if (param == 'tvoc-sgp') { return '4500'; }
    if (param == 'co2') { return '5000'; }
    if (param == 'aqi') { return '500'; }
    if (param == 'o3') { return '200'; }
    if (param == 'co') { return '50'; }
      if (param == 'no2') { return '2049'; }
      if (param == 'AirPressure') { return '600'; }
        if (param == 'PC0.1') { return '10000000'; }
        if (param == 'PC0.3') { return '1000000'; }
        if (param == 'PC0.5') { return '350000'; }
        if (param == 'PC1.0') { return '35000'; }
        if (param == 'PC2.5') { return '2500'; }
        if (param == 'PC5') { return '750'; }
        if (param == 'PC10') { return '500'; }
        if (param == 'MoldIndex') { return '75'; }
        if (param == 'O3_ppm') { return '105'; }
        if (param == 'O3_ppb ') { return '105'; }
        if(param == "pm01" || param == "pm03" || param == "pm05"  || param == "pm010" ) {return '150';}
  }

  valueToPercent(value, max) {
    return (value * 100) / max
  }
  getOutdoorPlaceData(id, location) {
    if (location != "") {
      return new Promise((resolve, reject) => {
        this.commonService.getWhioutLoader(`OutDoorAPI/GetPlace_PublicAPI?query=` + location).subscribe((response: any) => {
          let rawdata = JSON.parse(JSON.parse(response.data));
          let resultdata = rawdata.results[0].geometry.location;
          let latitude = resultdata.lat;
          let longitude = resultdata.lng;
          // setTimeout(() => { this.getOutdoorAPIData(id, latitude, longitude) }, 1000)

          // Task 7187: Integrate the OutDoorLocationData API on Portal. Date (29-Mar-2023)
          // this.getOutdoorAPIData(id, latitude, longitude);
          this.getOutdoorLocationData(location, id, latitude, longitude)

        }, error => {

        });
      });
    }
  }

  // ========================================== API Call end


  getBackgroundClass(param, value) {
    value = Math.round(value);
    let widgetcolor = null;
    if ((param == "temperature" && (value >= 64 && value <= 77)) || (param == "co2" && value >= 400 && value <= 800) || (param == "humidity" && value >= 40 && value <= 50) || (param == "pm10" && value >= 0 && value <= 54) || (param == "pm25" && value >= 0 && value <= 12.0) || (param == "tvoc-sgp" && value >= 0 && value <= 220) || (param == "o3" && value >= 0 && value <= 54)
    || (param == "AirPressure" && (value >= 950 && value <= 1040))
    || (param == "PC0.1" && (value >= 0 && value <= 2000000))
    || (param == "PC0.3" && (value >= 0 && value <= 100000))
    || (param == "PC0.5" && (value >= 0 && value <= 35000))
    || (param == "PC1.0" && (value >= 0 && value <= 3500))
    || (param == "PC2.5" && (value >= 0 && value <= 750))
    || (param == "PC5" && (value >= 0 && value <= 150))
    || (param == "PC10" && (value >= 0 && value <= 50))
    || (param == "MoldIndex" && (value >= 0 && value <= 50))
    || ((param == "O3_ppm" || param == "O3_ppb")  && (value >= 0 && value <= 54)) 
    || ((param == "pm01" || param == "pm03" || param == "pm05"  || param == "pm010" )  && (value >=0 && value <= 12)) 
    ) {
      return widgetcolor = "dd-small-box-green";
    }
    if ((param == "temperature" && ((value >= 62 && value <= 63) || (value >= 78 && value <= 79))) || (param == "co2" && value >= 801 && value <= 1500) || (param == "humidity" && ((value >= 35 && value <= 39) || (value >= 50.1 && value <= 60))) || (param == "pm10" && value >= 54.1 && value <= 154) || (param == "pm25" && value >= 12.1 && value <= 35.4) || (param == "tvoc-sgp" && value >= 221 && value <= 660) || (param == "o3" && value >= 55 && value <= 70)
    || (param == "AirPressure" && (value >= 600 && value <= 949)) ||  (param == "AirPressure" && (value >= 1041 && value <= 1100))
    || (param == "PC0.1" && (value >= 2000001 && value <= 3500000))
    || (param == "PC0.3" && (value >= 100001 && value <= 250000))
    || (param == "PC0.5" && (value >= 35001 && value <= 70000))
    || (param == "PC1.0" && (value >= 3501 && value <= 7000))
    || (param == "PC2.5" && (value >= 751 && value <= 1500))
    || (param == "PC5" && (value >= 151 && value <= 250))
    || (param == "PC10" && (value >= 51 && value <= 150))
    || ((param == "O3_ppm" || param == "O3_ppb")  && (value >= 55 && value <= 70))
    || ((param == "pm01" || param == "pm03" || param == "pm05"  || param == "pm010" )  && (value >12 && value <= 35)) 
    ) {
      return widgetcolor = "dd-small-box-yellow";
    }
    if ((param == "temperature" && ((value >= 51 && value <= 61) || (value >= 80 && value <= 89))) || (param == "co2" && value >= 1501 && value <= 2000) || (param == "humidity" && ((value >= 20 && value <= 34) || (value >= 60.1 && value <= 64))) || (param == "pm10" && value >= 154.1 && value <= 254) || (param == "pm25" && value >= 35.5 && value <= 55.4) || (param == "tvoc-sgp" && value >= 661 && value <= 2000) || (param == "o3" && value >= 71 && value <= 85)
    || (param == "PC0.1" && (value >= 3500001 && value <= 5000000))
    || (param == "PC0.3" && (value >= 250001 && value <= 500000))
    || (param == "PC0.5" && (value >= 70001 && value <= 150000))
    || (param == "PC1.0" && (value >= 7001 && value <= 15000))
    || (param == "PC2.5" && (value >= 1501 && value <= 2000))
    || (param == "PC5" && (value >= 251 && value <= 500))
    || (param == "PC10" && (value >= 151 && value <= 250))
    || (param == "MoldIndex" && (value >= 51 && value <= 75))
    || ((param == "O3_ppm" || param == "O3_ppb")  && (value >= 71 && value <= 85)) 
    || ((param == "pm01" || param == "pm03" || param == "pm05"  || param == "pm010" )  && (value >35 && value <= 55)) 
    ) {
      return widgetcolor = "dd-small-box-orange";
    }
    if ((param == "temperature" && ((value >= 48 && value <= 50) || (value >= 90 && value <= 92))) || (param == "co2" && value >= 2001 && value <= 2500) || (param == "humidity" && ((value >= 15 && value <= 19) || (value >= 64.1 && value <= 80))) || (param == "pm10" && value >= 254.1 && value <= 354) || (param == "pm25" && value >= 55.5 && value <= 150.4) || (param == "tvoc-sgp" && value >= 2001 && value <= 3500) || (param == "o3" && value >= 86 && value <= 105)
    || (param == "AirPressure" && (value >= 0 && value <= 600))
    || (param == "PC0.1" && (value >= 5000001 && value <= 10000000))
    || (param == "PC0.3" && (value >= 500001 && value <= 1000000))
    || (param == "PC0.5" && (value >= 150001 && value <= 350000))
    || (param == "PC1.0" && (value >= 15001 && value <= 35000))
    || (param == "PC2.5" && (value >= 2001 && value <= 2500))
    || (param == "PC5" && (value >= 501 && value <= 750))
    || (param == "PC10" && (value >= 251 && value <= 500))
    || (param == "MoldIndex" && (value >75))
    || ((param == "O3_ppm" || param == "O3_ppb")  && (value >= 86 && value <= 105))
    || ((param == "pm01" || param == "pm03" || param == "pm05"  || param == "pm010" )  && (value >55 && value <= 150)) 
    
    ) {
      return widgetcolor = "dd-small-box-red";
    }
    if ((param == "temperature" && ((value <= 48) || (value >= 92))) || (param == "co2" && value >= 2501) || (param == "humidity" && ((value >= 1 && value <= 14) || (value >= 81))) || (param == "pm10" && value >= 354) || (param == "pm25" && value >= 150.5) || (param == "tvoc-sgp" && value >= 3501) || (param == "pm25" && (value >= 201 && value <= 300)) || (param == "o3" && value >= 106 && value <= 200)
    || (param == "AirPressure" && value > 1100 )
    || (param == "PC0.1" && (value > 10000000))
    || (param == "PC0.3" && (value > 1000000))
    || (param == "PC0.5" && (value > 350000))
    || (param == "PC1.0" && (value > 35000))
    || (param == "PC2.5" && (value > 2500))
    || (param == "PC5" && (value > 750))
    || (param == "PC10" && (value > 500))
    || ((param == "O3_ppm" || param == "O3_ppb")  && (value > 105)) 
    || ((param == "pm01" || param == "pm03" || param == "pm05"  || param == "pm010" )  && (value > 150)) 
    ) {
      return widgetcolor = "dd-small-box-purple";
    }
    if ((param == "aqi" && (value >= 0 && value <= 50))) {
      return widgetcolor = "#34c38f";
    }
    if ((param == "aqi" && (value >= 51 && value <= 100))) {
      return widgetcolor = "#f1b44c";
    }
    if ((param == "aqi" && (value >= 101 && value <= 150))) {
      return widgetcolor = "#f1734f";
    }
    if ((param == "aqi" && (value >= 151 && value <= 200))) {
      return widgetcolor = "#ff0000";
    }
    if ((param == "aqi" && (value >= 201 && value <= 300))) {
      return widgetcolor = "#8B008B";
    }
    if ((param == "aqi" && (value >= 301))) {
      return widgetcolor = "#A52A2A";
    }
  }

  getChartClass(param, value) {
    value = Math.round(value);
    if ((param == "temperature" && (value >= 64 && value <= 77)) || (param == "co2" && value >= 400 && value <= 800) || (param == "humidity" && value >= 40 && value <= 50) || (param == "pm10" && value >= 0 && value <= 54) || (param == "pm25" && value >= 0 && value <= 12.0) || (param == "tvoc-sgp" && value >= 0 && value <= 220) || (param == "o3" && value >= 0 && value <= 54) || (param == "aqi" && value >= 0 && value <= 50)
    || (param == "AirPressure" && (value >= 950 && value <= 1040))
    || (param == "PC0.1" && (value >= 0 && value <= 2000000))
    || (param == "PC0.3" && (value >= 0 && value <= 100000))
    || (param == "PC0.5" && (value >= 0 && value <= 35000))
    || (param == "PC1.0" && (value >= 0 && value <= 3500))
    || (param == "PC2.5" && (value >= 0 && value <= 750))
    || (param == "PC5" && (value >= 0 && value <= 150))
    || (param == "PC10" && (value >= 0 && value <= 50))
    || (param == "MoldIndex" && (value >= 0 && value <= 50))
    || ((param == "O3_ppm" || param == "O3_ppb")  && (value >= 0 && value <= 54)) 
    || ((param == "pm01" || param == "pm03" || param == "pm05"  || param == "pm010" )  && (value >=0 && value <= 12)) 
    ) {
      return "#34c38f";
    }
    if ((param == "temperature" && ((value >= 62 && value <= 63) || (value >= 78 && value <= 79))) || (param == "co2" && value >= 801 && value <= 1500) || (param == "humidity" && ((value >= 35 && value <= 39) || (value >= 50.1 && value <= 60))) || (param == "pm10" && value >= 54.1 && value <= 154) || (param == "pm25" && value >= 12.1 && value <= 35.4) || (param == "tvoc-sgp" && value >= 221 && value <= 660) || (param == "o3" && value >= 55 && value <= 70) || (param == "aqi" && value >= 51 && value <= 100)
    || (param == "AirPressure" && (value >= 600 && value <= 949)) ||  (param == "AirPressure" && (value >= 1041 && value <= 1100))
    || (param == "PC0.1" && (value >= 2000001 && value <= 3500000))
    || (param == "PC0.3" && (value >= 100001 && value <= 250000))
    || (param == "PC0.5" && (value >= 35001 && value <= 70000))
    || (param == "PC1.0" && (value >= 3501 && value <= 7000))
    || (param == "PC2.5" && (value >= 751 && value <= 1500))
    || (param == "PC5" && (value >= 151 && value <= 250))
    || (param == "PC10" && (value >= 51 && value <= 150))
    || ((param == "O3_ppm" || param == "O3_ppb")  && (value >= 55 && value <= 70))
    || ((param == "pm01" || param == "pm03" || param == "pm05"  || param == "pm010" )  && (value >35 && value <= 55)) 
    ) {
      return "#f1b44c";
    }
    if ((param == "temperature" && ((value >= 51 && value <= 61) || (value >= 80 && value <= 89))) || (param == "co2" && value >= 1501 && value <= 2000) || (param == "humidity" && ((value >= 20 && value <= 34) || (value >= 60.1 && value <= 64))) || (param == "pm10" && value >= 154.1 && value <= 254) || (param == "pm25" && value >= 35.5 && value <= 55.4) || (param == "tvoc-sgp" && value >= 661 && value <= 2000) || (param == "o3" && value >= 71 && value <= 85) || (param == "aqi" && value >= 101 && value <= 150)
    || (param == "PC0.1" && (value >= 3500001 && value <= 5000000))
    || (param == "PC0.3" && (value >= 250001 && value <= 500000))
    || (param == "PC0.5" && (value >= 70001 && value <= 150000))
    || (param == "PC1.0" && (value >= 7001 && value <= 15000))
    || (param == "PC2.5" && (value >= 1501 && value <= 2000))
    || (param == "PC5" && (value >= 251 && value <= 500))
    || (param == "PC10" && (value >= 151 && value <= 250))
    || (param == "MoldIndex" && (value >= 51 && value <= 75))
    || ((param == "O3_ppm" || param == "O3_ppb")  && (value >= 71 && value <= 85)) 
    || ((param == "pm01" || param == "pm03" || param == "pm05"  || param == "pm010" )  && (value >55 && value <= 150)) 
    ) {
      return "#f1734f";
    }
    if ((param == "temperature" && ((value >= 48 && value <= 50) || (value >= 90 && value <= 92))) || (param == "co2" && value >= 2001 && value <= 2500) || (param == "humidity" && ((value >= 15 && value <= 19) || (value >= 64.1 && value <= 80))) || (param == "pm10" && value >= 254.1 && value <= 354) || (param == "pm25" && value >= 55.5 && value <= 150.4) || (param == "tvoc-sgp" && value >= 2001 && value <= 3500) || (param == "o3" && value >= 86 && value <= 105) || (param == "aqi" && value >= 151 && value <= 200)
    || (param == "AirPressure" && (value >= 0 && value <= 600))
    || (param == "PC0.1" && (value >= 5000001 && value <= 10000000))
    || (param == "PC0.3" && (value >= 500001 && value <= 1000000))
    || (param == "PC0.5" && (value >= 150001 && value <= 350000))
    || (param == "PC1.0" && (value >= 15001 && value <= 35000))
    || (param == "PC2.5" && (value >= 2001 && value <= 2500))
    || (param == "PC5" && (value >= 501 && value <= 750))
    || (param == "PC10" && (value >= 251 && value <= 500))
    || (param == "MoldIndex" && (value >75))
    || ((param == "O3_ppm" || param == "O3_ppb")  && (value >= 86 && value <= 105)) 
    || ((param == "pm01" || param == "pm03" || param == "pm05"  || param == "pm010" )  && (value >55 && value <= 150)) 
    
    ) {
      return "#ff0000";
    }
    if ((param == "temperature" && ((value <= 48) || (value >= 92))) || (param == "co2" && value >= 2501) || (param == "humidity" && ((value >= 1 && value <= 14) || (value >= 81))) || (param == "pm10" && value >= 354) || (param == "pm25" && value >= 150.5) || (param == "tvoc-sgp" && value >= 3501) || (param == "pm25" && (value >= 201 && value <= 300)) || (param == "o3" && value >= 106 && value <= 200) || (param == "aqi" && value >= 201)
    || (param == "AirPressure" && value > 1100 )
    || (param == "PC0.1" && (value > 10000000))
    || (param == "PC0.3" && (value > 1000000))
    || (param == "PC0.5" && (value > 350000))
    || (param == "PC1.0" && (value > 35000))
    || (param == "PC2.5" && (value > 2500))
    || (param == "PC5" && (value > 750))
    || (param == "PC10" && (value > 500))
    || ((param == "O3_ppm" || param == "O3_ppb")   && (value > 105)) 
    || ((param == "pm01" || param == "pm03" || param == "pm05"  || param == "pm010" )  && (value > 150)) 
    ) {
      return "#A52A2A";
    }
    if ((param == "aqi" && (value >= 0 && value <= 50))) {
      return "#34c38f";
    }
    if ((param == "aqi" && (value >= 51 && value <= 100))) {
      return "#f1b44c";
    }
    if ((param == "aqi" && (value >= 101 && value <= 150))) {
      return "#f1734f";
    }
    if ((param == "aqi" && (value >= 151 && value <= 200))) {
      return "#ff0000";
    }
    if ((param == "aqi" && (value >= 201 && value <= 300))) {
      return "#8B008B";
    }
    if ((param == "aqi" && (value >= 301))) {
      return "#A52A2A";
    }
  }
  getChartBackgroundClass(param, value) {
    value = Math.round(value);
    if ((param == "temperature" && (value >= 64 && value <= 77)) || (param == "co2" && value >= 0 && value <= 800) || (param == "humidity" && value >= 40 && value <= 50) || (param == "pm10" && value >= 0 && value <= 54) || (param == "pm25" && value >= 0 && value <= 12.0) || (param == "tvoc-sgp" && value >= 0 && value <= 220)
    || (param == "AirPressure" && (value >= 950 && value <= 1040))
    || (param == "PC0.1" && (value >= 0 && value <= 2000000))
    || (param == "PC0.3" && (value >= 0 && value <= 100000))
    || (param == "PC0.5" && (value >= 0 && value <= 35000))
    || (param == "PC1.0" && (value >= 0 && value <= 3500))
    || (param == "PC2.5" && (value >= 0 && value <= 750))
    || (param == "PC5" && (value >= 0 && value <= 150))
    || (param == "PC10" && (value >= 0 && value <= 50))
    || (param == "MoldIndex" && (value >= 0 && value <= 50))
    || ((param == "O3_ppm" || param == "O3_ppb")  && (value >= 0 && value <= 54)) 
    || ((param == "pm01" || param == "pm03" || param == "pm05"  || param == "pm010" )  && (value >= 0 && value <= 12)) 
    ) {
      return "big-box-green";
    }
    if ((param == "temperature" && ((value >= 62 && value <= 63) || (value >= 78 && value <= 79))) || (param == "co2" && value >= 801 && value <= 1500) || (param == "humidity" && ((value >= 35 && value <= 39) || (value >= 50.1 && value <= 60))) || (param == "pm10" && value >= 54.1 && value <= 154) || (param == "pm25" && value >= 12.1 && value <= 35.4) || (param == "tvoc-sgp" && value >= 221 && value <= 660)
    || (param == "AirPressure" && (value >= 600 && value <= 949)) ||  (param == "AirPressure" && (value >= 1041 && value <= 1100))
    || (param == "PC0.1" && (value >= 2000001 && value <= 3500000))
    || (param == "PC0.3" && (value >= 100001 && value <= 250000))
    || (param == "PC0.5" && (value >= 35001 && value <= 70000))
    || (param == "PC1.0" && (value >= 3501 && value <= 7000))
    || (param == "PC2.5" && (value >= 751 && value <= 1500))
    || (param == "PC5" && (value >= 151 && value <= 250))
    || (param == "PC10" && (value >= 51 && value <= 150))
    || ((param == "O3_ppm" || param == "O3_ppb")  && (value >= 55 && value <= 70))
    || ((param == "pm01" || param == "pm03" || param == "pm05"  || param == "pm010" )  && (value >12 && value <= 35)) 
    ) {
      return "big-box-yellow";
    }
    if ((param == "temperature" && ((value >= 51 && value <= 61) || (value >= 80 && value <= 89))) || (param == "co2" && value >= 1501 && value <= 2000) || (param == "humidity" && ((value >= 20 && value <= 34) || (value >= 60.1 && value <= 64))) || (param == "pm10" && value >= 154.1 && value <= 254) || (param == "pm25" && value >= 35.5 && value <= 55.4) || (param == "tvoc-sgp" && value >= 661 && value <= 2000)
    || (param == "PC0.1" && (value >= 3500001 && value <= 5000000))
    || (param == "PC0.3" && (value >= 250001 && value <= 500000))
    || (param == "PC0.5" && (value >= 70001 && value <= 150000))
    || (param == "PC1.0" && (value >= 7001 && value <= 15000))
    || (param == "PC2.5" && (value >= 1501 && value <= 2000))
    || (param == "PC5" && (value >= 251 && value <= 500))
    || (param == "PC10" && (value >= 151 && value <= 250))
    || (param == "MoldIndex" && (value >= 51 && value <= 75))
    || ((param == "O3_ppm" || param == "O3_ppb")  && (value >= 71 && value <= 85)) 
    || ((param == "pm01" || param == "pm03" || param == "pm05"  || param == "pm010" )  && (value >12 && value <= 35)) 
    ) {
      return "big-box-orange";
    }
    if ((param == "temperature" && ((value >= 48 && value <= 50) || (value >= 90 && value <= 92))) || (param == "co2" && value >= 2001 && value <= 2500) || (param == "humidity" && ((value >= 15 && value <= 19) || (value >= 64.1 && value <= 80))) || (param == "pm10" && value >= 254.1 && value <= 354) || (param == "pm25" && value >= 55.5 && value <= 150.4) || (param == "tvoc-sgp" && value >= 2001 && value <= 3500)
    || (param == "AirPressure" && (value >= 0 && value <= 600))
    || (param == "PC0.1" && (value >= 5000001 && value <= 10000000))
    || (param == "PC0.3" && (value >= 500001 && value <= 1000000))
    || (param == "PC0.5" && (value >= 150001 && value <= 350000))
    || (param == "PC1.0" && (value >= 15001 && value <= 35000))
    || (param == "PC2.5" && (value >= 2001 && value <= 2500))
    || (param == "PC5" && (value >= 501 && value <= 750))
    || (param == "PC10" && (value >= 251 && value <= 500))
    || (param == "MoldIndex" && (value >75))
    || ((param == "O3_ppm" || param == "O3_ppb")  && (value >= 86 && value <= 105))
    || ((param == "pm01" || param == "pm03" || param == "pm05"  || param == "pm010" )  && (value >55 && value <= 150)) 
    
    
    ) {
      return "big-box-red";
    }
    if ((param == "temperature" && ((value <= 48) || (value >= 92))) || (param == "co2" && value >= 2501) || (param == "humidity" && ((value >= 1 && value <= 14) || (value >= 81))) || (param == "pm10" && value >= 354) || (param == "pm25" && value >= 150.5) || (param == "tvoc-sgp" && value >= 3501) || (param == "pm25" && (value >= 201 && value <= 300))
    || (param == "AirPressure" && value > 1100 )
    || (param == "PC0.1" && (value > 10000000))
    || (param == "PC0.3" && (value > 1000000))
    || (param == "PC0.5" && (value > 350000))
    || (param == "PC1.0" && (value > 35000))
    || (param == "PC2.5" && (value > 2500))
    || (param == "PC5" && (value > 750))
    || (param == "PC10" && (value > 500))
    || ((param == "O3_ppm" || param == "O3_ppb")  && (value > 105)) 
    || ((param == "pm01" || param == "pm03" || param == "pm05"  || param == "pm010" )  && (value > 150)) 
    ) {
      return "big-box-purple";
    }
    if ((param == "aqi" && (value >= 0 && value <= 50)) || (param == "o3" && value >= 0 && value <= 54)) {
      return "big-box-green";
    }
    if ((param == "aqi" && (value >= 51 && value <= 100)) || (param == "o3" && value >= 55 && value <= 70)) {
      return "big-box-yellow";
    }
    if ((param == "aqi" && (value >= 101 && value <= 150)) || (param == "o3" && value >= 71 && value <= 85)) {
      return "big-box-orange";
    }
    if ((param == "aqi" && (value >= 151 && value <= 200)) || (param == "o3" && value >= 86 && value <= 105)) {
      return "big-box-red";
    }
    if ((param == "aqi" && (value >= 201 && value <= 300)) || (param == "o3" && value >= 106 && value <= 200)) {
      return "big-box-purple";
    }
    if ((param == "aqi" && (value >= 301)) || (param == "o3" && value >= 201)) {
      return "big-box-brown";
    }
  }
  getLocationNameData() {
    let locationData = [];
    this.kioskviewMetrixData.forEach(element => {
      if (element.location) {
        let locationdata = {
          location: element.location
        }
        locationData.push(locationdata.location);
      }
    });
    let uniqueLocation = [...new Set(locationData)];
    this.locationName = uniqueLocation.toString();;
  }
  getDeviceTypeID(deviceId) {
    this.commonService.getWhioutLoader('Device/GetDevice_PublicAPI/' + deviceId).subscribe((response: any) => {
      let data: any = response;
      this.DeviceTypeID = data.data.deviceTypeId;
      this.deviceTypeData[deviceId] = data.data.deviceTypeId
    });
  }
  getFormatedMatrices() {
    let all = [];
    this.kioskviewMetrixData.forEach(element => {
      if (element.data.name == 'Ozone, O3' && this.ppbExists == true) {
        element.data.average = this.ppbValue;
        element.data.unit = 'ppb';
      }
      if (element.location == '') {
        let rawsmall = {
          data: element
        };
        all.push(rawsmall.data);
      }
    });

    this.kioskviewMetrixDataSplit['all'] = all;
    this.loadUI = true;
  }
  convertTemp(celcius) {
    return Number(((celcius * 9 / 5) + 32).toFixed(2));
  }

  // Task 7187: Integrate the OutDoorLocationData API on Portal. Date (29-Mar-2023)
  getOutdoorLocationData(location, id, latitude = '41.04792', longitude = '-85.1537697') {
    let data = this.outdoorKioskviewMetrixData[id];
    return new Promise((resolve, reject) => {
      this.commonService.getWhioutLoader(`OutdoorLocationData/GetOutdoorLocationData_PublicAPI?location=` + location).subscribe((response: any) => {
        let rawdataAQIData = JSON.parse(response.data);
        let AQIData = rawdataAQIData.data[0];
        if (data.outdoormetricname == "AQI") {
          data['baqi'] = {};
          data['baqi']['location'] = data.location;
          data['baqi']['outdoormetricname'] = "Overall Index";
          data['baqi']['outdoormetricvalue'] = data.outdoormetricvalue;
          data['baqi']['name'] = "Overall Index";
          data['baqi']['units'] = "US";
          data['baqi']['value'] = Math.round((AQIData.aqi + Number.EPSILON) * 100) / 100 ?? null;
          data['baqi']['color'] = this.getWidgetColor('aqi', AQIData.aqi);
          data['baqi']['maxNumber'] = this.getTelematryMaxValue(data['baqi']['color'], 'aqi');
          data['baqi']['percent'] = this.getPercentage(AQIData.aqi, data['baqi']['maxNumber']);
          data['baqi']['CSS'] = this.getProgressBarCSS(data['baqi']['color'], data['baqi']['percent']) ?? null;
        }
        if (data.outdoormetricname == "PM 10") {
          data['pm10'] = {};
          data['pm10']['location'] = data.location;
          data['pm10']['outdoormetricname'] = data.outdoormetricname;
          data['pm10']['outdoormetricvalue'] = data.outdoormetricvalue;
          data['pm10']['name'] = "PM 10";
          data['pm10']['units'] = "µg/m³";
          data['pm10']['value'] = Math.round((AQIData.pm10 + Number.EPSILON) * 100) / 100 ?? null;
          data['pm10']['color'] = this.getWidgetColor('pm10', AQIData.pm10);
          data['pm10']['maxNumber'] = this.getTelematryMaxValue(data['pm10']['color'], 'pm10');
          data['pm10']['percent'] = this.getPercentage(AQIData.pm10, data['pm10']['maxNumber']);
          data['pm10']['CSS'] = this.getProgressBarCSS(data['pm10']['color'], data['pm10']['percent']) ?? null;
        }
        if (data.outdoormetricname == "PM 2.5") {
          data['pm25'] = {};
          data['pm25']['location'] = data.location;
          data['pm25']['outdoormetricname'] = data.outdoormetricname;
          data['pm25']['outdoormetricvalue'] = data.outdoormetricvalue;
          data['pm25']['name'] = "PM 2.5";
          data['pm25']['units'] = "µg/m³";
          data['pm25']['value'] = Math.round((AQIData.pm25 + Number.EPSILON) * 100) / 100 ?? null;
          data['pm25']['color'] = this.getWidgetColor('pm25', AQIData.pm25);
          data['pm25']['maxNumber'] = this.getTelematryMaxValue(data['pm25']['color'], 'pm25');
          data['pm25']['percent'] = this.getPercentage(AQIData.pm25, data['pm25']['maxNumber']);
          data['pm25']['CSS'] = this.getProgressBarCSS(data['pm25']['color'], data['pm25']['percent']) ?? null;
        }
        if (data.outdoormetricname == "Ozone, O3") {
          data['o3'] = {};
          data['o3']['location'] = data.location;
          data['o3']['outdoormetricname'] = data.outdoormetricname;
          data['o3']['outdoormetricvalue'] = data.outdoormetricvalue;
          data['o3']['name'] = "Ozone, O3";
          data['o3']['units'] = "ppb";
          data['o3']['value'] = Math.round((AQIData.o3 + Number.EPSILON) * 100) / 100 ?? null;
          data['o3']['color'] = this.getWidgetColor('o3', AQIData.o3);
          data['o3']['maxNumber'] = this.getTelematryMaxValue(data['o3']['color'], 'o3');
          data['o3']['percent'] = this.getPercentage(AQIData.o3, data['o3']['maxNumber']);
          data['o3']['CSS'] = this.getProgressBarCSS(data['o3']['color'], data['o3']['percent']) ?? null;
        }
        if (data.widgetsize == 3) {
          this.overAllGraph = data;
          this.outdoorLoadOverAllGraph(data[data.outdoormetricvalue]);
        }
        this.outdoorKioskviewMetrixData[id] = data;
      }, error => {
                console.log(error);
      });
      this.commonService.getWhioutLoader(`OutdoorLocationData/GetOutdoorWeatherData_PublicAPI?lat=` + latitude + `&lon=` + longitude).subscribe((response: any) => {
        // let rawdataWeatherData = JSON.parse(JSON.parse(response.data));
        let rawdataWeatherData = JSON.parse(response.data);   //Updated for New API integration under Task:7187, updated by Amit Singh.
        let WeatherData = rawdataWeatherData.data[0];
        if (data.outdoormetricname == "Temperature") {
          data['temp'] = {};
          data['temp']['location'] = data.location;
          data['temp']['outdoormetricname'] = data.outdoormetricname;
          data['temp']['outdoormetricvalue'] = 'temperature';
          data['temp']['name'] = "Temperature";
          data['temp']['units'] = "F";
          data['temp']['value'] = (Math.round((WeatherData.temp + Number.EPSILON) * 100) / 100).toFixed(1);
          data['temp']['color'] = this.getWidgetColor('temperature', (Math.round((WeatherData.temp + Number.EPSILON) * 100) / 100).toFixed(1));
          data['temp']['maxNumber'] = this.getTelematryMaxValue(data['temp']['color'], 'temperature');
          data['temp']['percent'] = this.getPercentage((Math.round((WeatherData.temp + Number.EPSILON) * 100) / 100).toFixed(1), data['temp']['maxNumber']);
          data['temp']['CSS'] = this.getProgressBarCSS(data['temp']['color'], data['temp']['percent']) ?? null;
        }
        if (data.outdoormetricname == "Humidity") {
          data['humidity'] = {};
          data['humidity']['location'] = data.location;
          data['humidity']['outdoormetricname'] = data.outdoormetricname;
          data['humidity']['outdoormetricvalue'] = data.outdoormetricvalue;
          data['humidity']['name'] = "Humidity";
          data['humidity']['units'] = "%";
          data['humidity']['value'] = Math.round((WeatherData.rh + Number.EPSILON) * 100) / 100;
          data['humidity']['color'] = this.getWidgetColor('humidity', Math.round((WeatherData.rh + Number.EPSILON) * 100) / 100);
          data['humidity']['maxNumber'] = this.getTelematryMaxValue(data['humidity']['color'], 'humidity');
          data['humidity']['percent'] = this.getPercentage(Math.round((WeatherData.rh + Number.EPSILON) * 100) / 100, data['humidity']['maxNumber']);
          data['humidity']['CSS'] = this.getProgressBarCSS(data['humidity']['color'], data['humidity']['percent']) ?? null;
        }
        if (data.widgetsize == 3) {
          this.overAllGraph = data;
          this.outdoorLoadOverAllGraph(data[data.outdoormetricvalue]);
        }
        this.outdoorKioskviewMetrixData[id] = data;
      }, error => {
      });
    });
  }

  getKioskViewGroup() {
    //https://localhost:44321/api/KioskViewGroup?kioskviewid=482&isActive=true&userid=1

    let params = this.kioskviewid;
    let groupArray = [];
    return new Promise((resolve, reject) => {
      this.commonService.getWhioutLoader('KioskViewGroup/GetKioskViewGroup_PublicAPI?isActive=true&kioskviewid=' + params).subscribe((response: any) => {
        let data: any = response;
        this.kioskviewDataGroup = data['data']['kioskViewGroupList'];
        this.kioskviewDataGroup.forEach(element => {
          this.kioskviewGrouplist[element.groupsid] = {}
          this.kioskviewGrouplist[element.groupsid]['kioskViewDetail'] = element
          if (!groupArray[element.groupsid]) {
            this.loadGroupList(element.groupsid);
            groupArray[element.groupsid] = 1;
          }
        });
        this.updateSubscription = interval(5000).subscribe(
          (val) => {
            this.processKiosViewGroupData(this.kioskviewGrouplist);
          });
        setTimeout(() => {
          this.getFormatedMatrices();
        }, 5000);
      }, error => {
        reject(error);
        this.commonComponentsService.openAlert("Error!", 'Error in getting Customer');
      });
    });
  }

  loadGroupList(Groupid) {
    let params = 'isActive=true&pageSize=' + 500 + '&pageNumber=' + 0 + '&groupId=' + Groupid;
    return new Promise((resolve, reject) => {
      this.commonService.getWhioutLoader('Group/GetGroupList_PublicAPI?' + params).subscribe((response: any) => {
        // this.commonService.getWhioutLoader('Group/GetGroupDropdown?').subscribe((response: any) => {
        let data: any = response;

        // this.kioskviewGrouplist[Groupid]['detail']= data;
        this.loadGroupListWithTelemetry(Groupid, data.data.groupList);
      }, error => {
        reject(error);
        this.commonComponentsService.openAlert("Error!", 'Error in getting Group');
      });
    });
  }

  getTelemetryAPILink(Groupid, deviceArray) {
    let link = 'Device/GetAllDevicesTelemetrySummary_PublicAPI';
    deviceArray.forEach((device, index) => {
      if (index == 0) {
        link = link.concat('?deviceId=', device);
      } else {
        link = link.concat('&deviceId=', device);
      }
      // this.kioskviewGrouplist[Groupid][device] = []
    });
    return link;
  }

  loadGroupListWithTelemetry(Groupid, dataArray) {
    let loadTeleData = []
    return new Promise((resolve, reject) => {
      dataArray.forEach(GroupList => {
        let devices = GroupList.devices.split(",");
        let param = this.getTelemetryAPILink(Groupid, devices);
        this.commonService.getWhioutLoader(param).subscribe((response: any) => {
          let data: any = response;
          let telemetrydata = this.telementryDataRestructure(Groupid, data.data);
          let loadTeleData1 = {
            'groupId': GroupList.groupid,
            'groupName': GroupList.groupname,
            'deviceCount': GroupList.devicesCount,
            'telemetryData': telemetrydata
          };
          // loadTeleData.push(loadTeleData1[0]);
          this.kioskviewGrouplist[Groupid]['GroupDetail'] = loadTeleData1
        }, error => {
          reject(error);
          this.commonComponentsService.openAlert("Error!", 'Error in getting Group');
        });

      });
      this.kioskviewGrouplist[Groupid]['Telemetry'] = [];
      // setTimeout(() => {
      //   this.processKiosViewGroupData(this.kioskviewGrouplist);
      // }, 3000);
      // setTimeout(() => {
      //   this.getFormatedMatrices();
      // }, 4000);
    });
  }
  telementryDataRestructure(Groupid, telementryData) {
    let deviceDataArray = []
    telementryData.forEach((teleData) => {
      if (teleData.status == null || teleData.status == "OFF") {
        let list = [];
        this.flair2Matric.forEach(element => {
          list[element] = 'OFF';
        });
        // deviceDataArray.push(list);
        // this.kioskviewGrouplist[Groupid][teleData.deviceId] = (list);
        this.kioskviewGrouplist[Groupid]['Telemetry'].push(list)
      }
      if (teleData.status != null && teleData.status != "OFF") {
        let data1 = JSON.parse(teleData.deviceTelemetryJson);
        let list = [];
        this.flair2Matric.forEach(element => {
          list[element] = 0;
        });
        this.flair2Matric.forEach(element => {
          if (teleData.deviceTypeId == 2 && data1['Temp_C'] != undefined) {
            data1['Temp_F'] = this.getFahrenheit(data1['Temp_C']);
          }
          // list[element] = teleData.deviceTypeId == 1 && teleData.deviceTypeId != 0 ? data1[this.masterMatricForFlair2[element]] != undefined ? data1[this.masterMatricForFlair2[element]] : 'N/A' : data1[this.masterMatricForFlair2[element]];
          if (teleData.deviceTypeId == 1 && teleData.deviceTypeId != 0) {
            if (data1[element] != undefined) {
              list[element] = data1[element]
            } else {
              list[element] = 0
            }
          } else {
            list[element] = data1[element]
          }
        });
        // deviceDataArray.push(list);
        // this.kioskviewGrouplist[Groupid][teleData.deviceId] = (list);
        this.kioskviewGrouplist[Groupid]['Telemetry'].push(list)
      }
    });
    // this.kioskviewGrouplist[Groupid]['Average'] = this.getTeledata(this.kioskviewGrouplist[Groupid]['Telemetry']);
    return this.getTeledata(this.kioskviewGrouplist[Groupid]['Telemetry']);
    // this.processKiosViewGroupData(this.kioskviewGrouplist);
  }
  getTeledata(dataArray) {
    let data = []
    dataArray.forEach(telemetry => {
      this.flair2Matric.forEach(matric => {
        if (!data[matric]) {
          data[matric] = [telemetry[matric]];
        } else {
          data[matric].push(telemetry[matric]);
        }
      });
    });
    return this.getAverage(data);;
  }
  getAverage(telemetryData) {
    let list = [];
    this.flair2Matric.forEach(matric => {

      let sum = 0;
      let length = 0;
      for (var i = 0; i < telemetryData[matric].length; i++) {
        if (telemetryData[matric][i] != "OFF" && telemetryData[matric][i] != 0) {
          sum += parseInt(telemetryData[matric][i], 10);
          length = length + 1;
        }
      }
      let avg = sum / length;
      list[matric] = Number.isNaN(avg) ? 0 : Math.round((avg + Number.EPSILON) * 100) / 100;
      // list[matric + 'class'] = this.matrixClassNo[matric] == undefined ? null : this.getColor(this.matrixClassNo[matric], Math.round((avg + Number.EPSILON) * 100) / 100);
    });
    return list;
  }
  getFahrenheit(CValue) {
    return CValue * (9 / 5) + 32;
  }
  processKiosViewGroupData(kioskviewGrouplist) {
    if (kioskviewGrouplist) {
      this.kioskviewMetrixDataSplit['groupData'] = []
      this.kioskviewDataGroup.forEach(elementdata => {
        let element = {};
        let matricName = this.masterMatricGroup[elementdata.metricnamekaiterra]
        let tempTely = {};
        if (this.kioskviewGrouplist[elementdata.groupsid] && this.kioskviewGrouplist[elementdata.groupsid]['GroupDetail'] && this.kioskviewGrouplist[elementdata.groupsid]['GroupDetail']['telemetryData']) {
          tempTely = this.kioskviewGrouplist[elementdata.groupsid].GroupDetail.telemetryData;
        } else {
          if (this.kioskviewGrouplistOld[elementdata.groupsid] && this.kioskviewGrouplistOld[elementdata.groupsid]['GroupDetail'] && this.kioskviewGrouplistOld[elementdata.groupsid]['GroupDetail']['telemetryData']) {
            tempTely = this.kioskviewGrouplistOld[elementdata.groupsid].GroupDetail.telemetryData;
          } else {
            tempTely = {
              "CO2_ppm": 'NA',
              "Humd": 'NA',
              "MoldIndex": 'NA',
              "O3_ppm": 'NA',
              "TVOCs_ppb": 'NA',
              "Temp_C": 'NA',
              "AQI": 'NA',
              "PC'NA'.1": 'NA',
              "PC'NA'.3": 'NA',
              "PC'NA'.5": 'NA',
              "PC1'NA'": 'NA',
              "PC1.'NA'": 'NA',
              "PC2.5": 'NA',
              "PC5": 'NA',
              "PM'NA'.1_ug/m3": 'NA',
              "PM'NA'.3_ug/m3": 'NA',
              "PM'NA'.5_ug/m3": 'NA',
              "PM1'NA'_ug/m3": 'NA',
              "PM1.'NA'_ug/m3": 'NA',
              "PM2.5_ug/m3": 'NA',
              "PM5_ug/m3": 'NA',
            }
          }
        }
        let average = (tempTely[elementdata.metricnamekaiterra]) ? tempTely[elementdata.metricnamekaiterra] : tempTely[elementdata.metricname];
        average = (average) ? average : tempTely[matricName];
        average = (average) ? average : 'NA';

        matricName = (tempTely[elementdata.metricnamekaiterra]) ? elementdata.metricnamekaiterra : elementdata.metricname;
        matricName = (matricName) ? matricName : this.masterMatricGroup[elementdata.metricnamekaiterra];

        let unit = (this.masterMatricForFlair2Unit[elementdata.metricnamekaiterra]) ? this.masterMatricForFlair2Unit[elementdata.metricnamekaiterra] : this.masterMatricForFlair2Unit[elementdata.metricname];
        unit = (unit) ? unit : this.masterMatricGroup[matricName];

        element['deviceName'] = elementdata.groupname
        element['location'] = ''
        element['data'] = {
          'name': matricName,
          'unit': (unit) ? unit : '',
          'average': average,
          'color': this.getWidgetColor(elementdata.metricname.toLowerCase(), average),
          'CSS': "halftop-border-green halfright-border-green",
          'percent': "percent",
          'maxNumber': '',
          'matricName': matricName
        };
        element['data']['maxNumber'] = this.getTelematryMaxValue(element['data']['color'], elementdata.metricname.toLowerCase())
        this.kioskviewMetrixDataSplit['groupData'].push(element);
      });
      this.kioskviewGrouplistOld = kioskviewGrouplist;
    }
  }
}
