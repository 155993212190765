<body data-topbar="dark" class="kiosk-page" [style.background]="'rgba(253, 253, 253, 0.5) url('+ kioskviewData.backgroundimagepath +') fixed center'" [style.background-size]="'cover'">

    <!-- Begin page -->
    <div id="layout-wrapper" class="overlay">
        <div class="page-content">
            <div class="container-fluid">
                <div class="row justify-content-md-center">
                    <!-- <h4>{{kioskviewData.kioskviewname}}</h4> -->
                    <div class="col-lg-10 col-md-10 col-sm-12 text-center">
                        <img *ngIf="kioskviewData.companylogopath" src="{{kioskviewData.companylogopath}}" class="image">
                        <h2>{{kioskviewData.customtitle}}</h2>
                        <h5 class="font-weight-semibold">{{kioskviewData.customnote}}</h5>
                    </div>
                </div>

                <div *ngIf="loadUI" class="row">
                    <div *ngIf="loadUI" class=" {{(outdoorKioskviewMetrixData.length>0)? 'col-lg-6 col-md-6 col-sm-12 border-right-grey ': 'col-lg-12 col-md-21 col-sm-12'}} ">
                        <div class="row">
                            <div *ngFor="let metrixData of kioskviewMetrixDataSplit['all']"
                                class="box-fix-size {{(outdoorKioskviewMetrixData.length>0)? 'col-xxl-4 col-xl-4 col-lg-4 col-md-3 col-sm-3': 'col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-3'}} in-count"
                                style="padding-bottom: 10px;">
                                <h6 *ngIf="kioskviewData.showindoorprojectname == true" class="small-main-title"
                                    ngbTooltip="{{metrixData.deviceName}}">{{(metrixData.deviceName.length > 11 ) ?
                                    (metrixData.deviceName).slice(0, 14)+'...' : (metrixData.deviceName)}}</h6>
                                <div class="small-box {{metrixData.data.CSS}}">
                                    <div class="sub-txt">{{(metrixData.data.unit)?metrixData.data.unit:'NA'}}</div>

                                    <h2 class="small-value">{{(metrixData.data.average)}}</h2>

                                    <h6 class="small-name">{{metrixData.data.name}}</h6>
                                </div>
                            </div>
                            <div *ngFor="let metrixData of kioskviewMetrixDataSplit['groupData']"
                                class="box-fix-size {{(outdoorKioskviewMetrixData.length>0)? 'col-xxl-4 col-xl-4 col-lg-4 col-md-3 col-sm-3 ': 'col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-3'}} in-count"
                                style="padding-bottom: 10px;">
                                    <h6 *ngIf="kioskviewData.showindoorprojectname == true" class="small-main-title" ngbTooltip ="{{metrixData.deviceName}}">{{(metrixData.deviceName.length > 11 )
                                    ? (metrixData.deviceName).slice(0, 14)+'...' : (metrixData.deviceName)}}</h6>
                                <div class="small-box {{metrixData.data.CSS}}">
                                    <div class="sub-txt">{{(metrixData.data.unit)?metrixData.data.unit:'NA'}}</div>

                                    <h2 class="small-value">{{(metrixData.data.average)}}</h2>

                                    <h6 class="small-name">{{metrixData.data.name}}</h6>
                                </div>
                            </div>
                        </div>
                    </div>


                    <!-- Indoor Air Quality End -->

                    <!-- Outdoor Air Quality start -->
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="row">
                            <ng-container *ngFor="let outdoormetrixData of outdoorKioskviewMetrixData">
                                <div class="box-fix-size col-xxl-4 col-xl-4 col-lg-4 col-md-3 col-sm-3 in-count"
                                    *ngIf="outdoormetrixData[outdoormetrixData.outdoormetricvalue] != undefined && outdoormetrixData[outdoormetrixData.outdoormetricvalue].value"
                                    style="padding-bottom: 10px;">
                                    <h6 *ngIf="kioskviewData.showoutdoorlocationname == true" class="small-main-title" ngbTooltip ="{{outdoormetrixData[outdoormetrixData.outdoormetricvalue].location}}">{{ outdoormetrixData[outdoormetrixData.outdoormetricvalue] != undefined ? (outdoormetrixData[outdoormetrixData.outdoormetricvalue].location.length > 11 ) ? (outdoormetrixData[outdoormetrixData.outdoormetricvalue].location).slice(0, 14)+'...' : (outdoormetrixData[outdoormetrixData.outdoormetricvalue].location) : ""}}</h6>
                                    <div class="small-box {{outdoormetrixData[outdoormetrixData.outdoormetricvalue].CSS != undefined ? outdoormetrixData[outdoormetrixData.outdoormetricvalue].CSS : '' }}">
                                        <div class="sub-txt">{{outdoormetrixData[outdoormetrixData.outdoormetricvalue] != undefined ? outdoormetrixData[outdoormetrixData.outdoormetricvalue].units : ""}}</div>
                                        <h2 class="small-value">{{outdoormetrixData[outdoormetrixData.outdoormetricvalue] != undefined ? outdoormetrixData[outdoormetrixData.outdoormetricvalue].value : ""}}</h2>
                                        <h6 class="small-name">{{outdoormetrixData[outdoormetrixData.outdoormetricvalue] != undefined ? outdoormetrixData[outdoormetrixData.outdoormetricvalue].name : ""}}</h6>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <!-- Outdoor Air Quality End -->
                </div>
                <div class="row">
                    <div class="col">
                        <div class="text-center barcode">
                            <qr-code value="{{qrlink}}" size="100" errorCorrectionLevel="M"></qr-code>
                            <br>
                            <small>Powered by ThinkLite Air</small>
                        </div>

                    </div>
                </div>
            </div> <!-- container-fluid -->
        </div>
        <!-- End Page-content -->
    </div>
</body>
